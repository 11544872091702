import React from 'react'
import searchIcon from "../assets/image/search.svg";
import closeIcone from "../assets/image/close.svg"
import "../assets/stylesheets/search.css"
import "../assets/stylesheets/home.css"

import {useState} from 'react';
import Cards from './Cards';
import {Link} from 'react-router-dom';

function Search({
                    placeholder,
                    data,
                    isSearch,
                    setIsSearch,
                    clearInput,
                    filteredData,
                    setFilteresData,
                    wordEnterd,
                    setWordEnterd
                }) {
    const [isOpen, setIsOpen] = useState(false);

    const handleFilter = (event) => {
        const searchWord = event.target.value;
        setWordEnterd(searchWord)
        const newFilter = data.filter((value) => {
            return value.title.includes(searchWord) || value.houseId.toString().includes(searchWord)
        });
        if (searchWord === "") {
            setFilteresData([])
        } else {
            setFilteresData(newFilter)
        }
    }


    return (
        <>
            <div className='search'>

                <div className={isSearch ? "header__search searchInputs serchInput__active " : "serch__none"}>
                    <div>

                        <img
                            className={isSearch ? "header__search-img" : "header__search-img-none"}
                            src={searchIcon}
                            alt="search"

                        />
                    </div>


                    <input
                        className="header__search-inp"
                        type="text"
                        placeholder={placeholder}
                        onChange={handleFilter}
                        value={wordEnterd}
                    />
                    <img src={closeIcone} className={isSearch ? "header__burger closer" : "header__burger-none"} alt=""
                         onClick={() => setIsSearch(false)}/>
                </div>

            </div>
            {filteredData.length != 0 && (
                <div className='dataResult'>
                    {
                        filteredData.map((value, key) => {
                            return <div key={value.houseId} onClick={clearInput}>
                                <Link className='serch__item' to={`/products/${value.houseId}`}>

                                    <p className='serch_txt'>{value.title}</p>

                                    {
                                        <img className='serch_img'
                                             src={`${process.env.REACT_APP_MY_API_KAY}/files?houseId=${value.houseId}&fileName=${value.pictures[0]}`}/>
                                    }
                                </Link>
                            </div>

                        })
                    }
                </div>
            )}
        </>
    )

}

export default Search