import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import { Link, useParams } from "react-router-dom";
import "../assets/stylesheets/header.css";
import "../assets/responsive/header.css";

import logo from "../assets/image/logo.png";
 import phoneIcon from "../assets/image/phone.svg";
import facebookIcon from "../assets/image/facebook.svg"
import InstaIcon from "../assets/image/instagram.svg"
import TelegramIcon from "../assets/image/telegram.svg"
import Search from "../components/Search";
import axios from "axios";
import searchIcon from "../assets/image/search.svg";

import hamburger from "../assets/image/hamburger.svg"

function Layoute() {
  const { t, i18n } = useTranslation(["home"]);
  const clearInput=()=>{
    setFilteresData([]);
    setWordEnterd("")

}
const [filteredData,setFilteresData]=useState([])
const [wordEnterd,setWordEnterd]=useState("")

  const [isOpen, setIsOpen] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const [serchData, setSerchData] = useState([]);
  const menuHeight = useRef()
  const[bioInfo,setBioInfo]=useState("")

  
const handelLanguageChange=(e)=>{
  i18n.changeLanguage(e.target.value)
}
  useEffect(() => {
    fetchPosts(i18n.language)

    if(localStorage.getItem("i18nextLng")?.length>2){
      i18next.changeLanguage("ru")
    }



  }, [i18n.language]);
  const fetchPosts = async (lng) => {
    console.log(`${process.env.REACT_APP_MY_API_KAY}/search?parameter=${bioInfo}&language=${lng}`);
    const res = await axios.get(`${process.env.REACT_APP_MY_API_KAY}/search?parameter=${bioInfo}&language=${lng}`)
    .then((request) => {
      setSerchData(request.data);
    })

    .catch((error) => {
      console.log(error);
    });
  }


  return (

    <>
    
    
   
    <>
    
      <header className="header header__active" onClick={clearInput}>
        <div className="header__container">
          <div className="header__wrapper">
          <div className={isSearch? "header__burger-none":"header__burger"} onClick={() => setIsOpen(!isOpen)}>
              <img src={hamburger} alt="open" />
            </div>
            <div className={isSearch?"header__logo-none":"header__logo"}>
              <Link
                className="header__menu-link"
                activeclassname="active"
                to="/"
              >
                <img className="header__logo-img" src={logo} alt="logo" title="SIEVN" />
              </Link>
            </div>
          <div>
              <Search  placeholder={t("serch")} data={serchData} setIsSearch={setIsSearch}
               isSearch={isSearch} isOpen={isOpen} clearInput={clearInput}
               filteredData={filteredData} setFilteresData={setFilteresData}
               wordEnterd={wordEnterd} setWordEnterd={setWordEnterd}/>
            <div className={isSearch?"header__burger-none":"header__burger"}  onClick={() => setIsSearch(!isSearch)}>
            <img className={isOpen?"yesserch":"noserch"} src={searchIcon} alt="search"/>
          </div>
            </div> 
       
            <div ref = {menuHeight}
            className={  isOpen ? "header__phone header__menu-active":"header__menuPhone"}
            >
              <img className="header__phone-img" src={phoneIcon} alt="search" />
              <a className="header__phone_number" href={`tel:${process.env.REACT_APP_MOBILE_NUMBER}`}>
                <p>{process.env.REACT_APP_MOBILE_NUMBER_TEXT}</p>
              </a>
            </div>
          </div>

     
        </div>
        <div className="header__bottom">
          <div className= "header__container header__wrapper ">
             
            <div 
            className= {  isOpen ? " header__bottom-icon header__menu-activenon":"header__bottom-icon"}
            >
            
            <a href="https://www.facebook.com/sievnrealestate/?ref=pages_you_manage" target="_blank" className="header__bottom-iconItem"><img src={facebookIcon} alt="facebook" title="Facebook" /></a>
            <a href="https://instagram.com/sievn_real_estate_agency?igshid=YmMyMTA2M2Y=" target="_blank" className="header__bottom-iconItem"><img src={InstaIcon} alt="instagram" title="Instagram" /></a>
            <a href="https://t.me/sievnrealestate" target="_blank" className="header__bottom-iconItem"><img src={TelegramIcon} alt="telegram" title="Telegram" /></a>
            
            </div>



      
        

            <div className="nomobile">


            <nav 
              className={
                isOpen ? "header__menu  header__menu-active" : "header__menu"
              }
            >
              <ul className="header__menu-list">
                <li className="header__menu-item">
                  <Link 
                  
                    className="header__menu-link link-1"
                    activeclassname="active"
                    to="/"
                    end="true"
                  >
                    {t("home")}
                   
                  </Link>
                </li>
                
                <li className="header__menu-item">
                  <Link
                   
                    className="header__menu-link link-1"
                    activeclassname="active"
                    to="about"
                  >
                      {t("aboutUs")}
                  </Link>
                  
                </li>
                

                <li className="header__menu-item">
                  <Link
                   
                    className="header__menu-link link-1"
                    activeclassname="active"
                    to="contacts"
                  >
                   {t("contact")}
                  </Link>
                </li>
                

              </ul>

                {/* unlucky  start*/}
                {/* <div     className={
                isOpen ? "unlucky-active" : "unlucky"
              }>
                  <div className="unlucky__flag"> 
                  <img src={RussiaFlag} alt="RussiaFlag" />
                  <p className="header__menu-link"
                 
                  >РУС</p>
                  </div>
                  <div className="unlucky__flag"> 
                  <img src={RussiaFlag} alt="RussiaFlag" />
                  <p className="header__menu-link"
                 
                  >ՀԱՅ</p>
                  </div>
                  <div className="unlucky__flag"> 
                  <img src={RussiaFlag} alt="RussiaFlag" />
                  <p className="header__menu-link"
                 
                  >ENG</p>
                  </div>
                  <div className="unlucky__phone">
                  <img className="header__phone-img" src={phoneIcon} alt="search" />
              <a className="header__phone_number" href="tel:+37441330760">
                <p>(+374) 94 17 02 77</p>
              </a>
                  </div>
              </div> */}

            {/* unlucky  end*/}
              
            </nav>
            </div>
              
            <div className="mobilearea " >
            <nav 
              className={
                isOpen ? "header__menu  header__menu-active" : "header__menu"
              }
              style={{height:"auto"}}
            >
              <ul className="header__menu-list">
                <li className="header__menu-item">
                  <Link 
                  onClick={() => setIsOpen(!isOpen)}
                    className="header__menu-link link-1"
                    activeclassname="active"
                    to="/"
                    end="true"
                  >
                       {t("home")}
                   
                  </Link>
                </li>
                                <div className="underline underlineHeader"></div>

                
                <li className="header__menu-item">
                  <Link
                   onClick={() => setIsOpen(!isOpen)}
                    className="header__menu-link link-1"
                    activeclassname="active"
                    to="about"
                  >
                   {t("aboutUs")}
                  </Link>
                  
                </li>
                                <div className="underline underlineHeader"></div>


                <li className="header__menu-item">
                  <Link
                   onClick={() => setIsOpen(!isOpen)}
                    className="header__menu-link link-1"
                    activeclassname="active"
                    to="contacts"
                  >
                    {t("contact")}
                  </Link>
                </li>
                <div className="underline underlineHeader"></div>

              </ul>

                {/* unlucky  start*/}

                <div     className={
                isOpen ? "unlucky-active" : "unlucky"
              }
           
              >
               
                <select className="flags"
                      value={localStorage.getItem("i18nextLng")}
                      onChange={handelLanguageChange}
                   >
                  <option value="ru" className="unlucky__flag header__menu-link">РУС</option>
                  <option value="am" className="unlucky__flag header__menu-link">ՀԱՅ</option>
                  <option value="en" className="unlucky__flag header__menu-link" >ENG</option>
                </select>
                  
                  <div className="unlucky__phone">
                  <img className="header__phone-img" src={phoneIcon} alt="search" />
              <a className="header__phone_number" href={`tel:${process.env.REACT_APP_MOBILE_NUMBER}`}>
                <p onClick={() => setIsOpen(!isOpen)}>{process.env.REACT_APP_MOBILE_NUMBER_TEXT}</p>
              </a>
                  </div>
                  <div 
            className= "headerIcon__mobile"
            >
            
            <a  href="https://www.facebook.com/sievnrealestate/?ref=pages_you_manage" target="_blank" className="header__bottom-iconItem"><img src={facebookIcon} alt="facebook" title="Facebook"  onClick={() => setIsOpen(!isOpen)}/></a>
            <a href="https://instagram.com/sievn_real_estate_agency?igshid=YmMyMTA2M2Y=" target="_blank" className="header__bottom-iconItem"><img src={InstaIcon} alt="instagram" title="Instagram" onClick={() => setIsOpen(!isOpen)} /></a>
            <a href="https://t.me/sievnrealestate" target="_blank" className="header__bottom-iconItem"><img src={TelegramIcon} alt="telegram" title="Telegram" onClick={() => setIsOpen(!isOpen)} /></a>
            
            </div>

              </div>

            {/* unlucky  end*/}
              
            </nav>
            </div>


            <div  className= {isOpen?"header_lang-none":"header__lang "} >

                <select className="flags" value={localStorage.getItem("i18nextLng")} onChange={handelLanguageChange}>
                  <option value="ru" className="unlucky__flag header__menu-link">РУС</option>
                   <option value="am" className="unlucky__flag header__menu-link">ՀԱՅ</option>
                  <option value="en" className="unlucky__flag header__menu-link" >ENG</option>
                </select>

    
              </div>
          
   
            </div>
          </div>
      </header>
            
    </> </>
  );
}

export default Layoute;
