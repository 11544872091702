import React  from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";



// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../assets/stylesheets/swiperHome.css";

// import required modules
import { Navigation } from "swiper";
import { Link } from "react-router-dom";

const topicArtefacts = ["Yes", "Да", "Այո"]

export default function SwiperHome({ data }) {
  
  const {t, i18n}=useTranslation(["card"])


  return (
    <>
      <Swiper
        slidesPerView={4}
        spaceBetween={10}
        slidesPerGroup={1}
        loop={true}
        loopFillGroupWithBlank={true}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper topSwiper"
        
 breakpoints={{
    // when window width is >= 320px
    300: {
      slidesPerView: 1,
      spaceBetween:10 ,
      
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 3,
      spaceBetween: 30
    },
    800: {
      slidesPerView:4 ,
      spaceBetween: 40
    }
  }}
      
      >
        {data.map((item) => {
                  if(item.currencyType==="USD"){
                    item.currencyType="$"
                  }else if(item.currencyType==="AMD"){
                    item.currencyType="֏"
                  }
                  else if(item.currencyType==="RUB"){
                    item.currencyType="₽"
                  }
          return (
            <div key={item.houseId}>
              {topicArtefacts.some(art=>art===item.isTopic) ? (
                <SwiperSlide  key={item.houseId}>
                     <Link
                        to={`/products/${item.houseId}`}
                       
                      >
                  <div className="swiper__card">
                    <div>
                      {topicArtefacts.some(art=>art===item.isTopic) ? (
                        <div className="urgent">{t("urgently")}</div>
                      ) : (
                        <>
                          {" "}
                          <div className="not__urgent"></div>
                        </>
                      )}
                      
                      {
        <img src={`${process.env.REACT_APP_MY_API_KAY}/files?houseId=${item.houseId}&fileName=${item.pictures[0]}`}/>
      }
                    </div>

                    <p className="swiper__card-price">{item.currencyType} {item.price} </p>
                    <p className="swiper__card-info">{item.title}</p>
                    <div className="swiper__card-text">
                      <p className="swiper__card-code">{t("code")}: {item.houseId}</p>
                   <p  className="swiper__card-more">  {t("more")}</p>
                       
                     
                    </div>
                  </div>
                  </Link>
                </SwiperSlide>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </Swiper>
    </>
  );
}
