import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";



import "../assets/stylesheets/products.css"
import atribut from "../assets/image/_.svg"
import whatsappIcon from "../assets/image/whatsapp.svg"
import massengerIcon from "../assets/image/massenger.svg"
import viberIcon from "../assets/image/viber.svg"
import phoneIcon from "../assets/image/phone2.svg"
import telegramIcon from "../assets/image/telegram2.svg"
import locationIcon from "../assets/image/location.svg"
import lineIcon from "../assets/image/Line 7.svg"
import Modal from "react-modal";
import axios from 'axios';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import PropTypes from 'prop-types'
import { Thumbs } from 'swiper'
// import required modules
import { FreeMode } from "swiper";


// Import Swiper React components

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


// import required modules

import closeIcone from "../assets/image/close2.svg"
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

import "../assets/stylesheets/contactOpen.css"
import { Link, useParams } from 'react-router-dom';
import { Slider } from '@mui/material'
import { selectedGridRowsCountSelector } from '@mui/x-data-grid'
import { display } from '@mui/system'
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Products(props) {
  const {t, i18n}=useTranslation(["products","filter"])



  let { id } = useParams();


  const [modalIsOpen, setIsOpen] = useState(false);
  const [showComercion, setShowCommercion] = useState(false)
  const [activeCom, setActiveCom] = useState(false);
  const [animalsActive, setanimalsActive] = useState(true)
  const [payment,setPayment]=useState(false)
  const [comer,setComer]=useState(false)
  const [propertShow,setPropertShow]=useState(false)

  const [state, setState] = useState({});
  const [selectImage, setSelectImage] = useState(null)


    

    
    // if (state.pictures === undefined) {
    //   console.log("aaaaaaaaa")
    // }

   
  
    const fetchPosts = async (lng) => {
      const res = await axios.get(`${process.env.REACT_APP_MY_API_KAY}/getDataById?houseId=${id}&language=${lng}`)
      .then((request) => {

        setState(request.data);
         setSelectImage(request.data.pictures[0]);
         if(request.data.paymentMethod==="Ежемесячная"|| request.data.paymentMethod==="Ամսեկան"|| request.data.paymentMethod==="Monthly"||
         request.data.paymentMethod==="Посуточная"|| request.data.paymentMethod==="Օրական"|| request.data.paymentMethod==="Daily"){
          setPayment(true)
     
        }
        if(request.data.paymentMethod==="Коммерческая"|| request.data.paymentMethod==="Կոմերցիոն"||request.data.paymentMethod==="Commercial"||
        request.data.paymentMethod==="Продажа"||request.data.paymentMethod==="sale"||request.data.paymentMethod==="Վաճառք"){
          setPayment(false)
        }
        if (request.data.rooms === null || request.data.toilets === null || request.data.balcony === null||
           request.data.buildingType==null || request.data.animals===null|| request.data.commerce === null) {
          setActiveCom(true)
        } else {
          setActiveCom(false)
        }
  
        if ( request.data.paymentMethod==="Продажа"||request.data.paymentMethod==="sale"||request.data.paymentMethod==="Վաճառք") {
          setanimalsActive(true)
        } else {
          setanimalsActive(false)
        }
        if( request.data.paymentMethod==="Продажа"||request.data.paymentMethod==="sale"||request.data.paymentMethod==="Վաճառք"){
          setShowCommercion(true)
        }
        if(request.data.paymentMethod==="Ежемесячная"|| request.data.paymentMethod==="Ամսեկան"|| request.data.paymentMethod==="Monthly"||
        request.data.paymentMethod==="Посуточная"|| request.data.paymentMethod==="Օրական"|| request.data.paymentMethod==="Daily"||
        request.data.paymentMethod==="Продажа"||request.data.paymentMethod==="sale"||request.data.paymentMethod==="Վաճառք"){
          setComer(true)
        } else if(request.data.paymentMethod==="Коммерческая"|| request.data.paymentMethod==="Կոմերցիոն"||request.data.paymentMethod==="Commercial"){
          setComer(false)
        }

        if(request.data.property==="Частный дом"||request.data.property==="Սեփական տուն"||request.data.property==="A private house"){
          setPropertShow(true)
        } else {
          setPropertShow(false)
        }
        // if (request.data.commercialType === null) {
        //   setShowCommercion(true)
        // } else {
        //   setShowCommercion(false)
        // }
        })
      .catch((error) => console.log(error))
    }
  
  
    useEffect(() => {
      fetchPosts(i18n.language)
    }, [i18n.language,id]);
    
    // axios.get(`${process.env.REACT_APP_MY_API_KAY}/getDataById?houseId=${id}&language=ru`)
    //   .then((request) => {

    //     setState(request.data);
    //      setSelectImage(request.data.pictures[0]);
    //      if(request.data.paymentMethod==="Ежемесячная"|| request.data.paymentMethod==="Посуточная"){
    //       setPayment(true)
     
    //     }
    //     if(request.data.paymentMethod==="Коммерческая"|| request.data.paymentMethod==="sale"){
    //       setPayment(false)
    //     }
    //     if (request.data.rooms === null || request.data.toilets === null || request.data.balcony === null||
    //        request.data.buildingType==null || request.data.animals===null) {
    //       setActiveCom(true)
    //     } else {
    //       setActiveCom(false)
    //     }
  
    //     if (request.data.paymentMethod==="sale") {
    //       setanimalsActive(true)
    //     } else {
    //       setanimalsActive(false)
    //     }
    //     if(request.data.paymentMethod==="sale"){
    //       setShowCommercion(true)
    //     }
    //     if(request.data.paymentMethod==="Ежемесячная"|| request.data.paymentMethod==="Посуточная"||request.data.paymentMethod==="sale"){
    //       setComer(true)
    //     } else if(request.data.paymentMethod==="Коммерческая"){
    //       setComer(false)
    //     }

    //     if(request.data.property==="Частный дом"){
    //       setPropertShow(true)
    //     } else {
    //       setPropertShow(false)
    //     }
    //     // if (request.data.commercialType === null) {
    //     //   setShowCommercion(true)
    //     // } else {
    //     //   setShowCommercion(false)
    //     // }
    //     })
    //   .catch((error) => console.log(error))
  
    


  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (state.currencyType === "USD") {
    state.currencyType = "$"
  } else if (state.currencyType === "AMD") {
    state.currencyType = "֏"
  }
  else if (state.currencyType === "RUB") {
    state.currencyType = "₽"
  }


  // const [sliderData,setSliderdata]=useState(state.pictures)
  // const handleClick=(index)=>{
  //   console.log(index)
  //   const slider=state.pictures[index]
  //   setSliderdata(slider)
  //   console.log(sliderData)
  // }

  //  const images=()=>{
  //    for(let i=0;i<=state.pictures.length;i++){
  //       console.log(i[0])
  //     }
  //  } 
if(selectImage==null){
  return(<p></p>)
}
let rent=" "
if(state.paymentMethod==="Ежемесячная"){
  rent="Ежемесячная"
}
if(state.paymentMethod==="Monthly"){
  rent="Monthly"
}
if(state.paymentMethod==="Ամսեկան"){
  rent="Ամսեկան"
}
if(state.paymentMethod==="Посуточная"){
  rent="Посуточная"
}
if(state.paymentMethod==="Daily"){
  rent="Daily"
}
if(state.paymentMethod==="Օրական"){
  rent="Օրական"
}



  return (

    <section>



      <div className='prod__container prod__wrapper prod'>

        <div className='product__start'>
          <Link className='productsStart' to="/">{t("home")}</Link>
          <img src={atribut} alt="" />
          <p>{t("code")} {state.houseId} </p>
        </div>
        {/* <div className='contacts__none'>
             <p className='product__icon-h'>Позвоните нам или напишите </p>
    <div className='product__icon-items'>
      <a href='https://api.whatsapp.com/send?phone=37494170277' className='product__icon-item whatsapp'><img src={whatsappIcon} alt="whatsapp" /></a>
      <a href="https://msng.link/o/?37494170277=vi" className='product__icon-item viber'><img src={viberIcon} alt="viber" /></a>
      <a  className='product__icon-item telegram'><img src={telegramIcon} alt="telegram" /></a>
      <a href='https://t.me/sievnrealestate' target="_blank" className='product__icon-item massenger'><img src={massengerIcon} alt="massenger" /></a>
      <a className='product__icon-item phone'><img src={phoneIcon} alt="phone2" /></a>
</div>
          </div> */}



        <div className='product__item' >

          <div className='product__item-img'>



            <div className='product___item-block'>
           
              <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
               
           
                {
                
                  
                  state?.pictures?.map((image) => (
                    <SwiperSlide >
                                 
                      {
                        
                        <img onClick={openModal} alt="product-img" src={`${process.env.REACT_APP_MY_API_KAY}/files?houseId=${id}&fileName=${image}`} />
                      }

                    </SwiperSlide>
                  ))
                }
              </Swiper>

            </div>



              <div className='product__item-none'>
                     
                     <img className='prodImg' onClick={openModal} alt="product-img" src={`${process.env.REACT_APP_MY_API_KAY}/files?houseId=${id}&fileName=${selectImage}`}/>
            </div>


          </div>


          <div className='product__item-icon'>
            <div className='product__item-iconImg'>
              <div className='product__iconImg'>
                {
                  state?.pictures?.map((image) => (

                    <img

                      onClick={() => { setSelectImage(image) }}
                      alt="product-img" src={`${process.env.REACT_APP_MY_API_KAY}/files?houseId=${id}&fileName=${image}`}
                      style={image === selectImage ? { border: "3px solid #144A52" } : {}}
                    />

                  ))
                }
              </div>

            </div>
            <div className="product__content-info">
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                overlayClassName="modal-overlay-product"
                className="modal-product"
              >
                <div className="modal__image">
                  <div className='modal__header'>
                    <p className="modal__image-text">
                      {state.bio}
                    </p>
                    <img src={closeIcone} alt="close" className="closeModalProduct" onClick={closeModal} />
                  </div>


                  <Swiper
                    cssMode={true}
                    navigation={true}
                    pagination={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    className="mySwiper swiperModal"
                  >       <div>
                      {
                        state?.pictures?.map((image) => (
                          <SwiperSlide key={image.id} className='ModalSwiper'>
                            {
                              <img onClick={openModal} alt="product-img" src={`${process.env.REACT_APP_MY_API_KAY}/files?houseId=${id}&fileName=${image}`} />
                            }

                          </SwiperSlide>
                        ))
                      }
                    </div>

                  </Swiper>
                </div>
              </Modal>
              <div className='contacts__block'>


                <p className='product__icon-h'>{t("callUs")}</p>
                <div className='product__icon-items'>
                  <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_MOBILE_NUMBER_1}`} target="blanck" className='product__icon-item whatsapp'><img src={whatsappIcon} alt="whatsapp" /></a>
                  <a href={`https://msng.link/o/?${process.env.REACT_APP_MOBILE_NUMBER_1}=vi`} target="blanck" className='product__icon-item viber'><img src={viberIcon} alt="viber" /></a>
                  <a href='https://t.me/sievnrealestate' target="blanck" className='product__icon-item telegram'><img src={telegramIcon} alt="telegram" /></a>
                  <a target="blank" href='https://www.m.me/sievnrealestate' className='product__icon-item massenger'><img src={massengerIcon} alt="messenger" /></a>
                  <a href={`tel:${process.env.REACT_APP_MOBILE_NUMBER}`} className='product__icon-item phone'><img src={phoneIcon} alt="phone2" /></a>
                </div>
              </div>

            </div>
          </div>


        </div>


        <div className='product__info'>

          <div className='product__info-global'>
            <p className='product__loccal-type local-title'> {state.title}</p>
            <p className='product__info-global1'>{state.bio}
           
            </p>
            <div className='product__info-location'>
              <p className='product__info-price'>{state.currencyType} {state.price} 
              <span className='price__txt'>{rent}</span>
              </p>
              <div className='info__location'>
                <div className='location__img'>      <img className='lineIcon' src={lineIcon} alt="" />
                  <img src={locationIcon} alt="" />
                </div>
                <p className='product__info-locInf'> {state.city} › {state.yerevanRegion} {t("street")} {state.street}</p>
              </div>

            </div>

            {/* <p className='product__loccal-type'> {state.title}</p> */}
          </div>

          <div className='product__info-loccal'>
            <div className='product__loccal-info'>

              <div className='product__loccal-block'>
                <p className='product__loccal-item'> {t("property")}</p>
                {/* <p className='product__loccal-type' style={{display:payment?"block":"none"}}> {state.paymentMethod}</p> */}
                <p className='product__loccal-type text__proper' style={{display:payment?"none":"flex"}}> 
                <span>{state.paymentMethod}</span> <span 
                style={{display:comer?"block":"none"}}
                >-{state.property}</span>
                
             
                </p>
                <p className='product__loccal-type' style={{display:payment?"block":"none"}}> {t("rent")}</p>

              </div>
              <div className={activeCom ? 'prod__none' : 'product__loccal-block'}>
              <div className={showComercion ? 'prod__none' : 'product__loccal-block'} >
                <p className='product__loccal-item'> {t("commerce")}</p>
                <p className='product__loccal-type' style={{display:payment?"none":"block"}}>
                  {state.commercialType} </p>
                  <p className='product__loccal-type prop' style={{display:payment?"flex":"none"}}>
                    
                  <span >{state.paymentMethod}-</span>
                  <span 
                style={{display:comer?"block":"none"}}
                >{state.property}</span>
                  </p>

              </div>
              </div>
              <div >
              <div className={propertShow ? 'prod__none':'product__loccal-block' }  >
                <p className='product__loccal-item'>{t("building")}</p>
                <p className='product__loccal-type'> {state.buildingType}</p>
              </div>
              </div>
              <div className='product__loccal-block'>
                {/* <p className='product__loccal-item'>Улица</p>
                            <p className='product__loccal-type'> {state.street}</p> */}
              </div>
              <div className={propertShow ? 'prod__none':'product__loccal-block' }>
                <p className='product__loccal-item'>{t("elevator")}</p>
                <p className='product__loccal-type'>{state.elevator} </p>
              </div>
              <div className={activeCom ? 'prod__none' : 'product__loccal-block'} >
                <p className='product__loccal-item'>{t("rooms")}</p>
                <p className='product__loccal-type'>{state.rooms}</p>
              </div>
              <div className={activeCom ? 'prod__none' : 'product__loccal-block'}  >
                <p className='product__loccal-item'>{t("bathrooms")}</p>
                <p className='product__loccal-type'> {state.toilets}</p>
              </div>


            </div>
            <div className='product__loccal-info'>
              <div className={propertShow ? 'prod__none':'product__loccal-block' }>
                <p className='product__loccal-item'>{t("newbuilding")}</p>
                <p className='product__loccal-type'> {state.newBuilt}</p>
              </div>

              <div className={propertShow ? 'prod__none':'product__loccal-block' }>
                <p className='product__loccal-item'>{t("floor")}</p>
                <p className='product__loccal-type'> {state.floor}/{state.floorNumber}</p>
              </div>

              <div className='product__loccal-block'>
                <p className='product__loccal-item'>{t("repairType")}</p>
                <p className='product__loccal-type'> {state.repairType}</p>
              </div>

              <div className={activeCom ? 'prod__none' : 'product__loccal-block'}>
                <p className='product__loccal-item'>{t("balcony")}</p>
                <p className='product__loccal-type'>{state.balcony} </p>
              </div>
              <div className={activeCom ? 'prod__none' : 'product__loccal-block'}>
              <div className={animalsActive ?  'prod__none':'product__loccal-block'} >
                <p className='product__loccal-item'>{t("animals")}</p>
                <p className='product__loccal-type'>{state.animals} </p>
              </div>
              </div>
              <div className='product__loccal-block'>
                <p className='product__loccal-item'>{t("totalarea")}</p>
                <p className='product__loccal-type'> {state.area} {t("sq")}</p>
              </div>

            </div>
          </div>
        </div>
      </div>









    </section>

  )
}



export default Products
