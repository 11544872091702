import React, { useEffect, useState } from "react";
import prev from "../assets/image/prev.svg";
import next from "../assets/image/next.svg";
import "../assets/stylesheets/pagination.css";
import "../assets/responsive/home.css";
import { useLocation } from "react-router";


function CardPagination({ totalCards, cardsPerPage, currentButton, setCurrentButton }) {
  const location = useLocation();
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => {
    
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 800, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
    const pageNumbers = []
    for (let i = 1; i <= Math.ceil(totalCards / cardsPerPage); i++) {
        pageNumbers.push(i)
    }


    // Array of buttons what we see on the page
    const [arrOfCurrButtons, setArrOfCurrButtons] = useState([])

    useEffect(() => {
        let tempNumberOfPages = [...arrOfCurrButtons]
        
        let dotsInitial = '...'
        let dotsLeft = '... '
        let dotsRight = ' ...'

        if (pageNumbers.length < 6) {
            tempNumberOfPages = pageNumbers
          }
       
        else if (currentButton >= 1 && currentButton <= 3) {
        tempNumberOfPages = [1, 2, 3, 4, dotsInitial, pageNumbers.length]
        }
    
        else if (currentButton === 4) {
        const sliced = pageNumbers.slice(0, 5)
        tempNumberOfPages = [...sliced, dotsInitial, pageNumbers.length]
        }

        else if (currentButton > 4 && currentButton < pageNumbers.length - 2) {               
            const sliced1 = pageNumbers.slice(currentButton - 2, currentButton)               
            const sliced2 = pageNumbers.slice(currentButton, currentButton + 1)                
            tempNumberOfPages = ([1, dotsLeft, ...sliced1, ...sliced2, dotsRight, pageNumbers.length]) 
          }
           
          else if (currentButton > pageNumbers.length - 3) {                
            const sliced = pageNumbers.slice(pageNumbers.length - 4)       
            tempNumberOfPages = ([1, dotsLeft, ...sliced])                        
          }
           
          else if (currentButton === dotsInitial) {
            setCurrentButton(arrOfCurrButtons[arrOfCurrButtons.length-3] + 1) 
          }
          else if (currentButton === dotsRight) {
            setCurrentButton(arrOfCurrButtons[3] + 2)
          }
       
          else if (currentButton === dotsLeft) {
            setCurrentButton(arrOfCurrButtons[3] - 2)
          }

        setArrOfCurrButtons(tempNumberOfPages)
    }, [totalCards, currentButton])

    return (
        <div className="page">
        <div className="pagination-container" onClick={scrollToTop}>
        <a  
        href="#"
        className={`${currentButton === 1 ? 'disabled' : ''}`}
        onClick={() => setCurrentButton(prev => prev <= 1 ? prev : prev - 1)}
      >
        <img src={prev} alt="prev" />
      </a>
        {
            arrOfCurrButtons.map((page, index) => (
                <a 
                    key={index}
                    className={`${currentButton === page ? 'active' : ''}`}
                    onClick={() => setCurrentButton(page)}
                >
                    {page}
                </a>
            ))
        }
<a
        href="#"
        className={`${currentButton === pageNumbers.length ? 'disabled' : ''}`}
        onClick={() => setCurrentButton(prev => prev >= pageNumbers.length ? prev : prev + 1)}
      >
       <img src={next} alt="next" />
      </a>
        </div>
        </div>
    );
}

export default CardPagination;
