import { useState } from "react";
import Modal from "react-modal";
import "../assets/stylesheets/homeOpen.css"
import whatsappIcon from "../assets/image/whatsapp.svg"
import massengerIcon from "../assets/image/massenger.svg"
import viberIcon from "../assets/image/viber.svg"
import phoneIcon from "../assets/image/phone2.svg"
import telegramIcon from "../assets/image/telegram2.svg"
import closeIcone from "../assets/image/close.svg"
import callIcon from "../assets/image/call.svg" 
import { useTranslation } from 'react-i18next';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function HomeOpen() {
  const { t, i18n } = useTranslation(["home"]);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => {
    
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="Container">
      <div id="wrapper">
     <button className='btn__up' style={{display: visible ? 'inline' : 'none'}} onClick={scrollToTop}>
    <i className="fa fa-arrow-up"></i>
     </button>
     <div className="fixes__btn" ><img src={callIcon} onClick={openModal}  /></div> 
     </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        overlayClassName="modal-overlay"
        className="modal-content"
      >
        <div className="modalArea">
           <img src={closeIcone} alt="close" className="closeModal"  onClick={closeModal}/>
        <div className="modal__content-info">
    
              <p className='modal__icon-h'>{t("call")}</p>
              <div className='modal__icon-items'>
                <a href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_MOBILE_NUMBER_1}`} className='modal__icon-item whatsapp'><img src={whatsappIcon} alt="whatsapp" /></a>
                <a href={`https://msng.link/o/?${process.env.REACT_APP_MOBILE_NUMBER_1}=vi`} className='modal__icon-item viber'><img src={viberIcon} alt="viber" /></a>
                <a href="https://t.me/sievnrealestate" target="_blank" className='modal__icon-item telegram'><img src={telegramIcon} alt="telegram" /></a>
                <a target="blank" href='https://www.m.me/sievnrealestate' className='modal__icon-item massenger'><img src={massengerIcon} alt="messenger" /></a>
                <a href={`tel:${process.env.REACT_APP_MOBILE_NUMBER}`} className='modal__icon-item phone'><img src={phoneIcon} alt="phone2" /></a>
          </div>

          </div>
          </div>
      </Modal>
    </div>
  );
}

export default HomeOpen;