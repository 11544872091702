import{BrowserRouter as Router, Routes, Route} from "react-router-dom"
import { Suspense } from "react";
import AboutUs from "./components/AboutUs";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Layoute from "./components/Layoute";
import Products from "./components/Products"
import ScrollToTop from "./components/ScrollToTop";



function App() {

  return (
<Suspense fallback={null}>
    <Router>
      <Layoute/>
      <ScrollToTop>

      <Routes>

        <Route index element={<Home/>}  />
        <Route path="/about" element={<AboutUs/>}/>
        <Route path="/contacts" element={<Contacts/>}/>
        <Route path="/products/:id" element={<Products   />} />
      </Routes>   
      </ScrollToTop>

      <Footer/>
    </Router>
    </Suspense>
  );
}

export default App;
