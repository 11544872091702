import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import "../assets/stylesheets/home.css"
import { useTranslation } from "react-i18next";
import CardPagination from './CardPagination';

function Cards({itemCount, posts, loading, totalCards, setCurrentButton, currentButton}) {
 
  const {t, i18n}=useTranslation(["card"])
  const [cardsPerPage] = useState(30) // 30 Per Page
       
  if (loading && posts.length === 0) {
    return <div className="loader">
    Loading...
      </div>
  }

    return (
    <>

<div className="cardandpag">
  <div className='scroller'>
    <div className={itemCount ? "products" : "products__inline"}>
      {posts.map((item) => {
        if(item.currencyType==="USD"){
          item.currencyType="$"
        }else if(item.currencyType==="AMD"){
          item.currencyType="֏"
        }
        else if(item.currencyType==="RUB"){
          item.currencyType="₽"
        }

        return (
          <Link
          to={`/products/${item.houseId}`}
          key={item.houseId}
        >
          <div
            className={
              itemCount ? "product__card" : "product__card-inline"
            }
          >
            <div>
              {item.isTopic === "Да" ? (
                <div className="urgent">{t("urgently")}</div>
              ) : (
                <>
                  {" "}
                  <div className="not__urgent"></div>
                </>
              )}
     

      {
        <img src={`${process.env.REACT_APP_MY_API_KAY}/files?houseId=${item.houseId}&fileName=${item.pictures[0]}`}/>
      }



            </div>
           
            <div
              className={
                itemCount ? "product__text" : "product__text-inline"
              }
            > 
              <p
                className={
                  itemCount
                    ? "product__card-info2"
                    : "product__card-inlineinfo2"
                }
              >
               {item.title}
              </p>
              <p className="product__card-price">
             
             
                
              {item.currencyType} {item.price}  </p>
              <p className={itemCount?"product__card-info":"product__card-info-title"}>{item.title}</p>  
              <p className={itemCount?"product__card-info-title":"product__card-info"}>{item.bio}</p>  

              <p
                className={
                  itemCount
                    ? "product__card-info3"
                    : "product__card-info3"
                }
              >
                "{t("property")} / {item.paymentMethod}"
              </p>

              <div
                className={
                  itemCount
                    ? "product__card-text"
                    : "product__card-textinline"
                }
              >
                <p className="product__card-code">{t("code")}: {item.houseId}</p>
                <p className="product__card-more">{t("more")}</p>
            
              

   

              </div>
            </div>
          </div>
          </Link>
        );
      })}



    </div> 
    </div>
    <CardPagination 
      totalCards={totalCards}
      cardsPerPage={cardsPerPage}
      currentButton={currentButton} 
      setCurrentButton={setCurrentButton}/>
    </div>

     </>
  )
}

export default Cards