import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// import axios from 'axios';

// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
// const languages=["en","ru","am"]

i18n

  .use(Backend)

  .use(LanguageDetector)
  .use(initReactI18next)

  .init({

    backend:{
        loadPath:"/assets/i18n/{{ns}}/{{lng}}.json"
    },
     fallbackLng: 'ru',

    debug: false,
    // whitelist:languages,
    ns:["about","contacts","products","home","filter"],

    interpolation: {
      escapeValue: false,
      formatSeparator:",",
    },
    react:{
      wait:true,
    }
  });


export default i18n;