import React from 'react'
import i18next from "i18next";
import { useTranslation } from 'react-i18next';


import { NavLink } from 'react-router-dom'
import "../assets/stylesheets/footer.css"

function Footer() {
  const {t}=useTranslation(["home"])

  return (
    <>
    <footer>
<div className='footer__area'>
              <ul className="footer__menu-list">
                <li className="footer__menu-item">
                  <NavLink
                    className="footer__menu-link"
                    activeclassname="active"
                    to="/"
                    end
                  >
                    {t("home")}
                  </NavLink>
                </li>
                <li className="footer__menu-item">
                  <NavLink
                    className="footer__menu-link"
                    activeclassname="active"
                    to="about"
                  >
                      {t("aboutUs")}
                  </NavLink>
                </li>
                <li className="footer__menu-item">
                  <NavLink
                    className="footer__menu-link"
                    activeclassname="active"
                    to="contacts"
                  >
                   {t("contact")}
                  </NavLink>
                </li>
              </ul>

     <a  href="https://www.websoft.am/" target="blanck">
      <p className='webSoft'>{t("company")}</p>
      
      </a>     
              
<p className='footer__end'> Copyright ©2024 President and Fellows of REAL ESTATE AGENCY</p>
</div>
    </footer>
    </>
  )
}

export default Footer