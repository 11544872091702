import "../assets/stylesheets/home.css";
import "../assets/responsive/home.css"
import SwiperHome from "./SwiperHome";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "../assets/stylesheets/pagination.css"
import Filters from "./Filters";
import { useLocation } from "react-router";


function Home() {
  const location = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
  const {t,i18n}=useTranslation(["home"])
const [state, setState] = useState([]);




  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_MY_API_KAY}/getAllData?language=ru`
  //     )
  //     .then((request) => {
  //        setState(request.data);
  //        console.log(setState)
  //     })

  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  const fetchPosts = async (lng) => {
    const res = await axios.get(`${process.env.REACT_APP_MY_API_KAY}/getAllDataByTopic?language=${lng}`)
    setState(res.data)
  }


  useEffect(() => {
    fetchPosts(i18n.language)
  }, [i18n.language]);

 
  return (
    <>
    {/* Добро пожаловать в */}
      <section className="homepage">
        <div className="home__start">
          <div className="content">
            <p className="content__text1">{t("welcome")}</p>
            <p className="content__text2">SiEVN REAL ESTATE AGENCY</p>
            <p className="content__text3">
            SiEVN  {t("welcomtxt")}
            </p>
          </div>
        </div>

        <div className="header__container header__wrapper">
          <div className="topprod">
            <div>
              <p className="top__text">{t("top")}</p>
            </div>
            <div></div>
          </div>
        </div>

        <div className="swiper__container">
          
          <SwiperHome  data={state} />
        </div>

       <Filters/>
     
  </section>
    </>
  );
}

export default Home;
