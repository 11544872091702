import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import "../assets/stylesheets/about.css"
import "../assets/responsive/aboutUs.css"
import about from "../assets/image/about.jpg"
import whatsappIcon from "../assets/image/whatsapp.svg"
import viberIcon from "../assets/image/viber.svg"
import telegram from "../assets/image/telegram2.svg"
import Footer from './Footer'
import AboutImg from "../assets/image/about.jpg"
import phoneIcon from "../assets/image/phone2.svg"

import employees from "../assets/image/Gor.jpg"
import employees1 from "../assets/image/Ani.jpg"
import employees2 from "../assets/image/Liana.jpg"
import employees3 from "../assets/image/Aren.jpg"
import employees4 from "../assets/image/Srbuhi.jpg"
import employees5 from "../assets/image/Gohar.jpg"
import employees6 from "../assets/image/Yuri.jpg"
import employees7 from "../assets/image/Karo.jpg"
import employees8 from "../assets/image/Grisha.jpg"


// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/pagination";
import "swiper/css/navigation";


// import required modules
import {Lazy, Pagination, Navigation} from "swiper";


function AboutUs() {

    const {t} = useTranslation(["about"])

    return (
        <>
            <section className='aboutpage'>
                <div className='about__start'>
                    <img src={AboutImg} alt=""/>
                </div>
                <div className="about__containers about__wrapper">


                    <div className='about__container'>
                        <div className='about__info'>
                            <p className='about__info-h'>{t("about")}</p>
                            <p className='about__info-text'>
                                {t("aboutInfo")}
                            </p>
                        </div>


                        <div className='about__time'>
                            <div className='about__time-h'>
                                <p>{t("employees")}</p>
                            </div>
                            {/* area 1 */}

                            <div className='about__peopel'>
                                <div className='about__peopel-card'>
                                    <img className='about__peopel-img' src={employees} alt="peopel"/>
                                    <div className='about__card-text'>
                                        <p className='about__peopel-name'>{t("nameGor")}</p>
                                        <p className='about__peopel-info'>{t("director")}</p>
                                        {/* <p className='about__peopel-text'>{t("specialistContact")}</p> */}
                                        <div className='about__peopel-icon'>

                                            <a target="blank" href="https://api.whatsapp.com/send?phone=37433010301"
                                               className='about__peopel-iconItem'>
                                                <img src={whatsappIcon} alt="whatsapp"/>
                                            </a>
                                            <a target="blank" href="tel:+37433010301"
                                               className='about__peopel-iconItem'>
                                                <img src={phoneIcon} alt="massenger"/>
                                            </a>
                                            <a target="blank" href="https://msng.link/o/?37433010301=vi"
                                               className='about__peopel-iconItem'>
                                                <img src={viberIcon} alt="viber"/>
                                            </a>

                                            <a target="blank" href="https://t.me/Gor_Arakelyan1994"
                                               className='about__peopel-iconItem'>
                                                <img src={telegram} alt="telegram"/>
                                            </a>
                                         
                                        </div>
                                    </div>

                                </div>
                                <div className='about__peopel-card'>
                                    <img className='about__peopel-img' src={employees1} alt="peopel"/>
                                    <div className='about__card-text'>

                                        <p className='about__peopel-name'>{t("nameAni")} </p>
                                        <p className='about__peopel-info'></p>
                                        <p className='about__peopel-text'>{t("specialistContact")}</p>
                                        <div className='about__peopel-icon'>
                                            <a target="blank" href="https://api.whatsapp.com/send?phone=37491222056"
                                               className='about__peopel-iconItem'>
                                                <img src={whatsappIcon} alt="whatsapp"/>
                                            </a>
                                            <a target="blank" href="tel:+37491222056"
                                               className='about__peopel-iconItem'>
                                                <img src={phoneIcon} alt="massenger"/>
                                            </a>

                                            <a target="blank" href="https://msng.link/o/?37491222056=vi"
                                               className='about__peopel-iconItem'>
                                                <img src={viberIcon} alt="viber"/>
                                            </a>

                                            <a target="blank" href="https://t.me/Ani_ani1234"
                                               className='about__peopel-iconItem'>
                                                <img src={telegram} alt="telegram"/>
                                            </a>

                                        </div>
                                    </div>
                                </div>
                                <div className='about__peopel-card'>
                                    <img className='about__peopel-img' src={employees2} alt="peopel"/>
                                    <div className='about__card-text'>

                                        <p className='about__peopel-name'>{t("nameLiana")} </p>
                                        {/* <p className='about__peopel-info'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy ․</p> */}
                                        <p className='about__peopel-text'>{t("specialistContact")}</p>
                                        <div className='about__peopel-icon'>
                                        <a target="blank" href="https://api.whatsapp.com/send?phone=37477030178"
                                           className='about__peopel-iconItem'>
                                            <img src={whatsappIcon} alt="whatsapp"/></a>
                                        <a target="blank" href="tel:+37477030178"
                                           className='about__peopel-iconItem'><img src={phoneIcon} alt="massenger"/></a>
                                        <a target="blank" href="https://msng.link/o/?37477030178=vi"
                                           className='about__peopel-iconItem'><img src={viberIcon} alt="viber"/></a>
                                        <a target="blank" href="https://t.me/LianLian93"
                                               className='about__peopel-iconItem'><img src={telegram} alt="telegram"/>
                                        </a>
                                    </div>
                                    </div>
                                </div>


                            </div>

                            {/* area 3 */}
                            <div className='about__peopel'>
                                <div className='about__peopel-card'>
                                    <img className='about__peopel-img' src={employees4} alt="peopel"/>
                                    <div className='about__card-text'>
                                        <p className='about__peopel-name'>{t("nameSrbuhi")} </p>
                                        {/* <p className='about__peopel-info'>директор</p> */}
                                        <p className='about__peopel-text'>{t("specialistContact")}</p>
                                        <div className='about__peopel-icon'>

                                            <a target="blank" href="https://api.whatsapp.com/send?phone=37493666047"
                                               className='about__peopel-iconItem'>
                                                <img src={whatsappIcon}alt="whatsapp"/>
                                            </a>
                                            <a target="blank" href="tel:+37493666047"
                                               className='about__peopel-iconItem'>
                                                <img src={phoneIcon} alt="massenger"/>
                                            </a>
                                            <a target="blank" href="https://msng.link/o/?37493666047=vi"
                                               className='about__peopel-iconItem'>
                                                <img src={viberIcon} alt="viber"/>
                                            </a>

                                            <a target="blank" href="https://t.me/Srbuhi_13"
                                               className='about__peopel-iconItem'>
                                                <img src={telegram} alt="telegram"/>
                                            </a>
                                               
                                        </div>
                                    </div>

                                </div>
                                <div className='about__peopel-card'>
                                    <img className='about__peopel-img' src={employees3} alt="peopel"/>
                                    <div className='about__card-text'>

                                        <p className='about__peopel-name'>{t("nameAren")}  </p>
                                        <p className='about__peopel-info'></p>
                                        <p className='about__peopel-text'>{t("specialistContact")}</p>
                                        <div className='about__peopel-icon'>
                                            <a target="blank" href="https://api.whatsapp.com/send?phone=37493940122"
                                               className='about__peopel-iconItem'>
                                                <img src={whatsappIcon}alt="whatsapp"/>
                                            </a>

                                            <a target="blank" href="tel:+37493940122"
                                               className='about__peopel-iconItem'>
                                                <img src={phoneIcon} alt="massenger"/>
                                            </a>

                                            <a target="blank" href="https://msng.link/o/?37493940122=vi"
                                               className='about__peopel-iconItem'>
                                                <img src={viberIcon} alt="viber"/>
                                            </a>

                                            <a target="blank" href="https://t.me/Aren_2711"
                                               className='about__peopel-iconItem'>
                                                <img src={telegram} alt="telegram"/>
                                            </a>

                                        </div>
                                    </div>
                                </div>
                                <div className='about__peopel-card'>
                                    <img className='about__peopel-img' src={employees5} alt="peopel"/>
                                    <div className='about__card-text'>

                                        <p className='about__peopel-name'>{t("nameGohar")}  </p>
                                        {/* <p className='about__peopel-info'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy ․</p> */}
                                        <p className='about__peopel-text'>{t("specialistContact")}</p>
                                        <div className='about__peopel-icon'>

                                            <a target="blank" href="https://api.whatsapp.com/send?phone=37494300760"
                                               className='about__peopel-iconItem'>
                                                <img src={whatsappIcon}alt="whatsapp"/>
                                            </a>
                                            <a target="blank" href="tel:+37494300760"
                                               className='about__peopel-iconItem'>
                                                <img src={phoneIcon} alt="massenger"/>
                                            </a>
                                            <a target="blank" href="https://msng.link/o/?37494300760=vi"
                                               className='about__peopel-iconItem'>
                                                <img src={viberIcon} alt="viber"/>
                                            </a>
                                            <a target="blank" href="https://t.me/gogkarapetyan"
                                               className='about__peopel-iconItem'>
                                                <img src={telegram} alt="telegram"/>
                                            </a>

                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className='about__peopel'>
                                <div className='about__peopel-card'>
                                    <img className='about__peopel-img' src={employees6} alt="peopel"/>
                                    <div className='about__card-text'>
                                        <p className='about__peopel-name'>{t("nameYuri")}  </p>
                                        {/* <p className='about__peopel-info'>директор</p> */}
                                        <p className='about__peopel-text'>{t("specialistContact")}</p>
                                        <div className='about__peopel-icon'>

                                            <a target="blank" href="https://api.whatsapp.com/send?phone=37498098020"
                                               className='about__peopel-iconItem'>
                                                <img src={whatsappIcon}alt="whatsapp"/>
                                             </a>

                                            <a target="blank" href="tel:+37498098020"
                                               className='about__peopel-iconItem'>
                                                <img src={phoneIcon} alt="massenger"/>
                                            </a>

                                            <a target="blank" href="https://msng.link/o/?37498098020=vi"
                                               className='about__peopel-iconItem'>
                                                <img src={viberIcon} alt="viber"/>
                                            </a>

                                            <a target="blank" href="t.me/@IuriiMiagkov"
                                               className='about__peopel-iconItem'>
                                                <img src={telegram} alt="telegram"/>
                                            </a>

                                        </div>
                                    </div>

                                </div>

                                <div className='about__peopel-card'>
                                    <img className='about__peopel-img' src={employees7} alt="peopel"/>
                                    <div className='about__card-text'>
                                        <p className='about__peopel-name'>{t("nameKaro")}</p>
                                        {/* <p className='about__peopel-info'>директор</p> */}
                                        <p className='about__peopel-text'>{t("specialistContact")}</p>
                                        <div className='about__peopel-icon'>
                                            <a target="blank" href="https://api.whatsapp.com/send?phone=37498021600"
                                               className='about__peopel-iconItem'>
                                                <img src={whatsappIcon}  alt="whatsapp"/>
                                            </a>

                                            <a target="blank" href="tel:+37498021600"
                                               className='about__peopel-iconItem'>
                                                <img src={phoneIcon} alt="massenger"/>
                                            </a>

                                            <a target="blank" href="https://msng.link/o/?37498021600=vi"
                                               className='about__peopel-iconItem'>
                                                <img src={viberIcon} alt="viber"/>
                                            </a>

                                            <a target="blank" href="https://t.me/Karo_Poloyan"
                                               className='about__peopel-iconItem'>
                                                <img src={telegram} alt="telegram"/>
                                            </a>   

                                        </div>
                                    </div>

                                </div>

                                <div className='about__peopel-card'>
                                    <img className='about__peopel-img' src={employees8} alt="peopel"/>
                                    <div className='about__card-text'>
                                        <p className='about__peopel-name'>{t("nameGrisha")}</p>
                                        {/* <p className='about__peopel-info'>директор</p> */}
                                        <p className='about__peopel-text'>{t("specialistContact")}</p>
                                        <div className='about__peopel-icon'>

                                            <a target="blank" href="https://api.whatsapp.com/send?phone=37499500678"
                                               className='about__peopel-iconItem'>
                                                <img src={whatsappIcon}alt="whatsapp"/>
                                            </a>
                                            <a target="blank" href="tel:+37499500678"
                                               className='about__peopel-iconItem'>
                                                <img src={phoneIcon} alt="massenger"/>
                                            </a>
                                            <a target="blank" href="https://msng.link/o/?37499500678=vi"
                                               className='about__peopel-iconItem'>
                                                <img src={viberIcon} alt="viber"/>
                                            </a>
                                            <a target="blank" href="https://t.me/gnazaretyan"
                                               className='about__peopel-iconItem'>
                                                <img src={telegram} alt="telegram"/>
                                            </a>

                                        </div>
                                    </div>

                                </div>

                                


                            </div>
                        </div>


                    </div>


                </div>


                {/* about us swiper//////////////////////////////////////////////////////////////////////////////////////////////// */}
                <>
                    <Swiper
                        style={{
                            "--swiper-navigation-color": "#fff",
                            "--swiper-pagination-color": "#fff",
                        }}
                        lazy={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Lazy, Pagination, Navigation]}
                        className="mySwiper aboutSwiper"
                    >
                        <SwiperSlide>
                            <div className='about__peopel-card'>
                                <img className="about__peopel-img" src={employees} alt="peopel"/>
                                <div className='about__card-text'>
                                    <p className='about__peopel-name'>{t("nameGor")}</p>
                                    <p className='about__peopel-info'>{t("director")}</p>
                                    {/* <p className='about__peopel-text'>{t("specialistContact")}</p> */}
                                    <div className='about__peopel-icon'>

                                        <a target="blank" href="https://api.whatsapp.com/send?phone=37433010301"
                                        className='about__peopel-iconItem'>
                                            <img src={whatsappIcon} alt="whatsapp"/>
                                        </a>
                                        <a target="blank" href="tel:+37433010301"
                                        className='about__peopel-iconItem'>
                                            <img src={phoneIcon} alt="massenger"/>
                                        </a>
                                        <a target="blank" href="https://msng.link/o/?37433010301=vi"
                                        className='about__peopel-iconItem'>
                                            <img src={viberIcon} alt="viber"/>
                                        </a>

                                        <a target="blank" href="https://t.me/Gor_Arakelyan1994"
                                        className='about__peopel-iconItem'>
                                            <img src={telegram} alt="telegram"/>
                                        </a>

                                    </div>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='about__peopel-card'>
                                <img className='about__peopel-img' src={employees1} alt="peopel"/>
                                <div className='about__card-text'>

                                    <p className='about__peopel-name'>{t("nameAni")} </p>
                                    <p className='about__peopel-info'></p>
                                    <p className='about__peopel-text'>{t("specialistContact")}</p>
                                    <div className='about__peopel-icon'>
                                            <a target="blank" href="https://api.whatsapp.com/send?phone=37491222056"
                                               className='about__peopel-iconItem'>
                                                <img src={whatsappIcon} alt="whatsapp"/>
                                            </a>
                                            <a target="blank" href="tel:+37491222056"
                                               className='about__peopel-iconItem'>
                                                <img src={phoneIcon} alt="massenger"/>
                                            </a>

                                            <a target="blank" href="https://msng.link/o/?37491222056=vi"
                                               className='about__peopel-iconItem'>
                                                <img src={viberIcon} alt="viber"/>
                                            </a>

                                            <a target="blank" href="https://t.me/Ani_ani1234"
                                               className='about__peopel-iconItem'>
                                                <img src={telegram} alt="telegram"/>
                                            </a>

                                        </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='about__peopel-card'>
                                <img className='about__peopel-img' src={employees2} alt="peopel"/>
                                <div className='about__card-text'>

                                    <p className='about__peopel-name'>{t("nameLiana")} </p>
                                    {/* <p className='about__peopel-info'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy ․</p> */}
                                    <p className='about__peopel-text'>{t("specialistContact")}</p>
                                    <div className='about__peopel-icon'>
                                        <a target="blank" href="https://api.whatsapp.com/send?phone=37477030178"
                                           className='about__peopel-iconItem'>
                                            <img src={whatsappIcon} alt="whatsapp"/></a>
                                        <a target="blank" href="tel:+37477030178"
                                           className='about__peopel-iconItem'><img src={phoneIcon} alt="massenger"/></a>
                                        <a target="blank" href="https://msng.link/o/?37477030178=vi"
                                           className='about__peopel-iconItem'><img src={viberIcon} alt="viber"/></a>
                                        <a target="blank" href="https://t.me/LianLian93"
                                               className='about__peopel-iconItem'><img src={telegram} alt="telegram"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='about__peopel-card'>
                                <img className='about__peopel-img' src={employees4} alt="peopel"/>
                                <div className='about__card-text'>
                                    <p className='about__peopel-name'>{t("nameSrbuhi")} </p>
                                    {/* <p className='about__peopel-info'>директор</p> */}
                                    <p className='about__peopel-text'>{t("specialistContact")}</p>
                                        <div className='about__peopel-icon'>
                                            
                                            <a target="blank" href="https://api.whatsapp.com/send?phone=37493666047"
                                               className='about__peopel-iconItem'>
                                                <img src={whatsappIcon}alt="whatsapp"/>
                                            </a>
                                            <a target="blank" href="tel:+37493666047"
                                               className='about__peopel-iconItem'>
                                                <img src={phoneIcon} alt="massenger"/>
                                            </a>
                                            <a target="blank" href="https://msng.link/o/?37493666047=vi"
                                               className='about__peopel-iconItem'>
                                                <img src={viberIcon} alt="viber"/>
                                            </a>

                                            <a target="blank" href="https://t.me/Srbuhi_13"
                                               className='about__peopel-iconItem'>
                                                <img src={telegram} alt="telegram"/>
                                            </a>
                                               
                                        </div>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='about__peopel-card'>
                                <img className='about__peopel-img' src={employees3} alt="peopel"/>
                                <div className='about__card-text'>

                                    <p className='about__peopel-name'>{t("nameAren")}  </p>
                                    <p className='about__peopel-info'></p>
                                    <p className='about__peopel-text'>{t("specialistContact")}</p>
                                    <div className='about__peopel-icon'>
                                            <a target="blank" href="https://api.whatsapp.com/send?phone=37493940122"
                                               className='about__peopel-iconItem'>
                                                <img src={whatsappIcon}alt="whatsapp"/>
                                            </a>

                                            <a target="blank" href="tel:+37493940122"
                                               className='about__peopel-iconItem'>
                                                <img src={phoneIcon} alt="massenger"/>
                                            </a>

                                            <a target="blank" href="https://msng.link/o/?37493940122=vi"
                                               className='about__peopel-iconItem'>
                                                <img src={viberIcon} alt="viber"/>
                                            </a>

                                            <a target="blank" href="https://t.me/Aren_2711"
                                               className='about__peopel-iconItem'>
                                                <img src={telegram} alt="telegram"/>
                                            </a>

                                        </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='about__peopel-card'>
                                <img className='about__peopel-img' src={employees5} alt="peopel"/>
                                <div className='about__card-text'>

                                    <p className='about__peopel-name'>{t("nameGohar")}  </p>
                                    {/* <p className='about__peopel-info'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy ․</p> */}
                                    <p className='about__peopel-text'>{t("specialistContact")}</p>
                                    <div className='about__peopel-icon'>

                                            <a target="blank" href="https://api.whatsapp.com/send?phone=37494300760"
                                            className='about__peopel-iconItem'>
                                                <img src={whatsappIcon}alt="whatsapp"/>
                                            </a>
                                            <a target="blank" href="tel:+37494300760"
                                            className='about__peopel-iconItem'>
                                                <img src={phoneIcon} alt="massenger"/>
                                            </a>
                                            <a target="blank" href="https://msng.link/o/?37494300760=vi"
                                            className='about__peopel-iconItem'>
                                                <img src={viberIcon} alt="viber"/>
                                            </a>
                                            <a target="blank" href="https://t.me/gogkarapetyan"
                                            className='about__peopel-iconItem'>
                                                <img src={telegram} alt="telegram"/>
                                            </a>

                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='about__peopel-card'>
                                <img className='about__peopel-img' src={employees6} alt="peopel"/>
                                <div className='about__card-text'>
                                    <p className='about__peopel-name'>{t("nameYuri")}  </p>
                                    {/* <p className='about__peopel-info'>директор</p> */}
                                    <p className='about__peopel-text'>{t("specialistContact")}</p>
                                    <div className='about__peopel-icon'>

                                        <a target="blank" href="https://api.whatsapp.com/send?phone=37498098020"
                                        className='about__peopel-iconItem'>
                                            <img src={whatsappIcon}alt="whatsapp"/>
                                        </a>

                                        <a target="blank" href="tel:+37498098020"
                                        className='about__peopel-iconItem'>
                                            <img src={phoneIcon} alt="massenger"/>
                                        </a>

                                        <a target="blank" href="https://msng.link/o/?37498098020=vi"
                                        className='about__peopel-iconItem'>
                                            <img src={viberIcon} alt="viber"/>
                                        </a>

                                        <a target="blank" href="t.me/@IuriiMiagkov"
                                        className='about__peopel-iconItem'>
                                            <img src={telegram} alt="telegram"/>
                                        </a>

                                    </div>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='about__peopel-card'>
                                <img className='about__peopel-img' src={employees7} alt="peopel"/>
                                <div className='about__card-text'>
                                    <p className='about__peopel-name'>{t("nameKaro")}  </p>
                                    {/* <p className='about__peopel-info'>директор</p> */}
                                    <p className='about__peopel-text'>{t("specialistContact")}</p>
                                    <div className='about__peopel-icon'>
                                            <a target="blank" href="https://api.whatsapp.com/send?phone=37498021600"
                                               className='about__peopel-iconItem'>
                                                <img src={whatsappIcon}  alt="whatsapp"/>
                                            </a>

                                            <a target="blank" href="tel:+37498021600"
                                               className='about__peopel-iconItem'>
                                                <img src={phoneIcon} alt="massenger"/>
                                            </a>

                                            <a target="blank" href="https://msng.link/o/?37498021600=vi"
                                               className='about__peopel-iconItem'>
                                                <img src={viberIcon} alt="viber"/>
                                            </a>

                                            <a target="blank" href="https://t.me/Karo_Poloyan"
                                               className='about__peopel-iconItem'>
                                                <img src={telegram} alt="telegram"/>
                                            </a>   

                                        </div>
                                </div>

                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='about__peopel-card'>
                                    <img className='about__peopel-img' src={employees8} alt="peopel"/>
                                    <div className='about__card-text'>
                                        <p className='about__peopel-name'>{t("nameGrisha")}</p>
                                        {/* <p className='about__peopel-info'>директор</p> */}
                                        <p className='about__peopel-text'>{t("specialistContact")}</p>
                                        <div className='about__peopel-icon'>

                                            <a target="blank" href="https://api.whatsapp.com/send?phone=37499500678"
                                               className='about__peopel-iconItem'>
                                                <img src={whatsappIcon}alt="whatsapp"/>
                                            </a>
                                            <a target="blank" href="tel:+37499500678"
                                               className='about__peopel-iconItem'>
                                                <img src={phoneIcon} alt="massenger"/>
                                            </a>
                                            <a target="blank" href="https://msng.link/o/?37499500678=vi"
                                               className='about__peopel-iconItem'>
                                                <img src={viberIcon} alt="viber"/>
                                            </a>
                                            <a target="blank" href="https://t.me/gnazaretyan"
                                               className='about__peopel-iconItem'>
                                                <img src={telegram} alt="telegram"/>
                                            </a>

                                        </div>
                                    </div>

                                </div>

                        </SwiperSlide>

                    </Swiper>

                    
                    
                </>


            </section>
        </>
    )
}

export default AboutUs