import React, { useEffect, useState } from 'react'
import "../assets/stylesheets/contacts.css"
import whatsappIcon from "../assets/image/whatsapp.svg"
import massengerIcon from "../assets/image/massenger.svg"
import viberIcon from "../assets/image/viber.svg"
import phoneIcon from "../assets/image/phone2.svg"
import telegramIcon from "../assets/image/telegram2.svg"
import mapImg from "../assets/image/map.jpg"
import gmailIcon from "../assets/image/gmail.svg"
import locationIcon from "../assets/image/location.svg"
import phone3Icon from "../assets/image/phone3.svg"
import axios from 'axios'
import Modal from "react-modal";
import "../assets/stylesheets/homeOpen.css"
import closeIcone from "../assets/image/close.svg"
// import { GoogleMap, useJsApiLoader ,useLoadScript,Marker} from '@react-google-maps/api';

import { useTranslation } from "react-i18next";

import "../assets/stylesheets/contactOpen.css"
import { t } from 'i18next'



function Contacts() {
  const {t}=useTranslation(["contact"])

  const [modalIsOpen, setIsOpen] = useState(false);
  const [formErrors,setFormErrors]=useState({})
  const [isSubmit,setIsSubmit]=useState(false)
  const [userData, setUserData]=useState({
    name:"",
    email:"",
    message:""
  })


  function openModal() {

      setIsOpen(true);
      
  }

  function closeModal() {
    setIsOpen(false);
    setUserData({ name:"",
    email:"",
    message:""})

  }
  function handle(e){
      const newData={...userData}
      newData[e.target.id]=e.target.value
      setUserData(newData)
  }
  function submit(e){
    e.preventDefault();
    setFormErrors(validate(userData))
    setIsSubmit(true)

  }

  useEffect(()=>{
    console.log(formErrors)
    if(Object.keys(formErrors).length===0 && isSubmit){
      axios.get(`${process.env.REACT_APP_MY_API_KAY}/email?name=${userData.name}&email=${userData.email}&message=${userData.message}`)
      .then((res)=>{
          console.log(res.data)
      })
      .catch((error)=>{
        console.error(error);
      })
    }
  },[formErrors])


  const validate=()=>{
    const errors={}
    if(userData.name===""){
      errors.name=t("enterName")
    }
    if(userData.email===""){
      errors.email=t("enterEmail")
    }
    if(userData.message===""){
      errors.message=t("enterMessage")
    }
    else{
        openModal()
        }
    return errors
  }



  return (
    <>
    <section className='contactpage'>
    <div className="header__container header__wrapper contact__container">


          <div className='card__icon'>
              <p className='card__icon-h'>{t("callUs")} </p>
              <div className='card__icon-items'>
                <a target="blank" href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_MOBILE_NUMBER_1}`} className='card__icon-item whatsapp'><img src={whatsappIcon} alt="whatsapp" /></a>
                <a target="blank"  href={`https://msng.link/o/?${process.env.REACT_APP_MOBILE_NUMBER_1}=vi`} className='card__icon-item viber'><img src={viberIcon} alt="viber" /></a>
                <a target="blank" href='https://t.me/sievnrealestate'  className='card__icon-item telegram'><img src={telegramIcon} alt="telegram" /></a>
                <a target="blank" href='https://www.m.me/sievnrealestate' className='card__icon-item massenger'><img src={massengerIcon} alt="messenger" /></a>
                <a   href={`tel:${process.env.REACT_APP_MOBILE_NUMBER}`} className='card__icon-item phone'><img src={phoneIcon} alt="phone2" /></a>
              </div>
              <p className='card__icon-text'>{t("contactText")}</p>
              <div className='card__icon-input'>
              
                 <input className='card__input-item'
                  type="text" 
                   placeholder={t("fullname")}
                  name='user_name' 
                  onChange={(e)=>handle(e)}
                  id="name"
                  value={userData.name}
                  />
                  <p className='errMess'>{formErrors.name}</p> 
                 
                <input className='card__input-item' 
                type="email"
                 placeholder={t("email")}
                  name='user_email'
                  id="email"
                   onChange={(e)=>handle(e)}
                   value={userData.email}
                   />
                    <p className='errMess'>{formErrors.email}</p> 
                  
                <textarea 
                className='card__input-textarea'
                 name="message" 
                  cols="30" rows="10"
                   placeholder={t("message")}
                   id="message"
                   onChange={(e)=>handle(e)}
                   value={userData.message} 
                   />
                    <p className='errMess'>{formErrors.message}</p>
                    
                  
                </div>
                <button className='card__input-btn'  onClick={(e)=>submit(e)}> {t("send")}</button>
     
                <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        overlayClassName="modal-overlay"
        className="modal-content"
      >
           <img src={closeIcone} alt="close" className="closeModal" type="submit" value="Send"  onClick={closeModal}/>
        <div className="modal__contacts">
                <p className="modal__contacts-info">
                Ваше сообщение было 
успешно отправлено.
                </p>

          </div>
      </Modal>

          </div>

        <div className='card__map'>
          <div className='card__map-img'>
          <div className="google-map-code">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12187.400321719386!2d44.5240408!3d40.2123977!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd1184871f5b%3A0x359af854704c9a8!2sSievn%20Real%20Estate%20Agency!5e0!3m2!1sen!2sam!4v1710864517646!5m2!1sen!2sam" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
          {/* <img src={mapImg} alt="map" /> */}
          </div>
            <div className='card__map-icon'>
              <div className='card__map-item'>
                  <img src={locationIcon} alt="location" />
                  <a href=''> {t("address")}</a>
              </div>
              <div className='card__map-item'>
                  <img src={gmailIcon} alt="gmail" />
                  <a  href = "mailto: sievnagency77@gmail.com">sievnagency77@gmail.com</a>
              </div>
              <div className='card__map-item'>
                  <img src={phone3Icon} alt="phone3" />
                  <a href={`tel:${process.env.REACT_APP_MOBILE_NUMBER}`}>{process.env.REACT_APP_MOBILE_NUMBER_TEXT}</a>
              </div>
            

            </div>
        </div>


      </div>


    </section>

    </>
  )
}

export default Contacts