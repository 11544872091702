import React, { useState, useEffect } from "react";
import "../assets/stylesheets/filter.css";
import chervon from "../assets/image/chervon.svg";
import chervonClose from "../assets/image/chervon__close.svg";
import newcolor from "../assets/image/chervonClose2.svg";
import newcolor2 from "../assets/image/chervon2.svg";
import axios from "axios";
import "../assets/stylesheets/range.css";
import treeIcon from "../assets/image/three.svg";
import fourIcon from "../assets/image/foure.svg";
import Cards from "../components/Cards";
import filterIcon from "../assets/image/filter.svg";
import "../assets/responsive/filter.css";
import closeIcon from "../assets/image/close.svg";
import HomeOpen from "./HomeOpen";
import ErrorFilter from "./ErrorFilter";
import { color, style } from "@mui/system";
import { useTranslation } from "react-i18next";

const MAX = 8;
const MAX2 = 8;

function Filters() {


  const { t, i18n } = useTranslation(["filter"]);

  const [isOpen, setIsOpen] = useState(true);

  const [itemCount, setItemCount] = useState(true);

  const [itemFilter, setItemFilter] = useState(false);
  const [commerciaFilter, setCommerciaFilter] = useState(false);
  const [rentFilter, setRentFilter] = useState(false);
  const [regionFilter, setRegionFilter] = useState(false);
  const [inYerevanFilter, setInYerevanFilter] = useState(false);
  const [typeFilter, setTypeFilter] = useState(false);
  const [repairFilter, setRepairFilter] = useState(false);
  const [propertyFilter, setPropertyFilter] = useState(false);

  const [animalsActive, setanimalsActive] = useState(false);
  const [showAnimalsFilter, setShowAnimalsFilter] = useState(false);

  const [showRegionFilter, setShowRegionFilter] = useState(false);
  const [showcommerciaFilter, setShowCommerciaFilter] = useState(false);

  const [showClose, setShowClose] = useState(false);
  const [closeComercion, setCloseComercion] = useState(false);
  const [showCloseCity, setShowCloseCity] = useState(false);
  const [showinYerevan, setShowinYerevan] = useState(false);
  const [showTypeFilter, setShowTypeFilter] = useState(false);
  const [showRepairFilter, setShowRepairFilter] = useState(false);
  const [showPropertyFilter, setShowPropertyFilter] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState("");
  const [property, setProperty] = useState("");
  const [commercialType, setCommercialType] = useState("");
  const [city, setCity] = useState("");
  const [inYerevan, setInYerevan] = useState("");
  const [buildingType, setbuildingType] = useState("");
  const [repairType, setrepairType] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [newBuilt, setNewBuilt] = useState("");
  const [animals, setAnimals] = useState("");
  const [balcony, setBalcony] = useState("");
  const [minArea, setMinArea] = useState("");
  const [maxArea, setMaxArea] = useState("");
  const [rooms, setRooms] = useState(0);
  const [toilets, setToilets] = useState(0);

  const newbuilt = (event) => {
    setNewBuilt(event.target.value);
  };
  const animalsChange = (event) => {
    setAnimals(event.target.value);
  };
  const balconyChange = (event) => {
    setBalcony(event.target.value);
  };
  const RentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const minprice = (event) => {
    setMinPrice(event.target.value);
  };
  const maxprice = (event) => {
    setMaxPrice(event.target.value);
  };
  const minarea = (event) => {
    setMinArea(event.target.value);
  };

  const maxarea = (event) => {
    setMaxArea(event.target.value);
  };

  const getBackgroundSize = () => {
    return { backgroundSize: `${(rooms * 100) / MAX}% 100%` };
  };
  const getBackgroundSize2 = () => {
    return { backgroundSize: `${(toilets * 100) / MAX}% 100%` };
  };
 
  // New Code Start
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCards, setTotalCards] = useState(0);
  const [currentButton, setCurrentButton] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const fetchPosts = async (lng) => {
    setLoading(true);
    // const res = await axios.get(`${process.env.REACT_APP_MY_API_KAY}/getAllData?language=${lng}`)
    const res = await axios.get(
      `${process.env.REACT_APP_MY_API_KAY}/getAllDataByPage?language=${lng}&page=0&size=30`
    );
    setPosts(res.data);
    setLoading(false);
  };
  const cardsPagesCount = async () => {
    const dividend = await axios.get(
      `${process.env.REACT_APP_MY_API_KAY}/getQuantity`
    );
    setTotalCards(dividend.data);
  };
  const filterCardspagesCount = async () => {
    const dividend = await axios.get(
      `${process.env.REACT_APP_MY_API_KAY}/getFilteredDataQuantity?property=${property}&paymentMethod=${paymentMethod}&commercialType=${commercialType}&rooms=${rooms}&toilets=${toilets}&city=${city}&yerevanRegion=${inYerevan}&buildingType=${buildingType}&newBuilt=${newBuilt}&minArea=${minArea}&maxArea=${maxArea}&repairType=${repairType}&animals=${animals}&balcony=${balcony}&minPrice=${minPrice}&maxPrice=${maxPrice}&language=${i18n.language}`
    );
    setTotalCards(dividend.data);
  };
  const nextPageAllCards = async (id) => {
    setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_MY_API_KAY}/getAllDataByPage?language=${
        i18n.language
      }&page=${id - 1}&size=30`
    );
    setPosts(res.data);
    setLoading(false);
  };
  const nextPageFilterCards = async (id) => {
    setLoading(true);
    // const res= await axios.get(`${process.env.REACT_APP_MY_API_KAY}/getAllDataByPage?language=${lng}&page=${id-1}&size=50`)
    const res = await axios.get(`${
      process.env.REACT_APP_MY_API_KAY
    }/filterByPage?property=${property}&paymentMethod=${paymentMethod}&commercialType=${commercialType}&rooms=${rooms}&toilets=${toilets}&city=${city}&yerevanRegion=${inYerevan}&
        buildingType=${buildingType}&newBuilt=${newBuilt}&minArea=${minArea}&maxArea=${maxArea}
        &repairType=${repairType}&animals=${animals}
        &balcony=${balcony}&minPrice=${minPrice}&maxPrice=${maxPrice}&language=${
      i18n.language
    }&page=${id - 1}&size=30`);
    setPosts(res.data);
    setLoading(false);
  };
  useEffect(() => {
    // if(isFilter === false) {
    //   fetchPosts(i18n.language)
    // }
    cardsPagesCount();
    setIsFilter(false);
    // nextPageAllCards(currentButton)
  }, [i18n.language]);
  useEffect(() => {
    if (isFilter === false) {
      nextPageAllCards(currentButton);
    } else {
      nextPageFilterCards(currentButton);
    }
  }, [currentButton]);

  const [visible, setVisible] = useState(false)
  const toggleVisible = () => {
    
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 800, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);

  const filterProduct = (e) => {
    scrollToTop()
    setIsOpen(!isOpen)
    e.preventDefault();
    e.stopPropagation();
    axios
      .get(
        `${process.env.REACT_APP_MY_API_KAY}/filterByPage?property=${property}&paymentMethod=${paymentMethod}&commercialType=${commercialType}&rooms=${rooms}&toilets=${toilets}&city=${city}&yerevanRegion=${inYerevan}&
        buildingType=${buildingType}&newBuilt=${newBuilt}&minArea=${minArea}&maxArea=${maxArea}
        &repairType=${repairType}&animals=${animals}
        &balcony=${balcony}&minPrice=${minPrice}&maxPrice=${maxPrice}&language=${i18n.language}&page=0&size=30`
      )
      .then((request) => {
        setPosts(request.data);
        filterCardspagesCount();
        setIsFilter(true);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  // New Code End
  const closePaymentMethod = () => {
    setPaymentMethod("");
    setShowClose(false);
  };
  const closeProperty = () => {
    setProperty("");
    setShowPropertyFilter(false);
  };
  const closeCity = () => {
    setCity("");
    setShowCloseCity(false);
  };
  const closeYerevan = () => {
    setInYerevan("");
    setShowinYerevan(false);
  };
  const closesType = () => {
    setbuildingType("");
    setShowTypeFilter(false);
  };
  const closeRepair = () => {
    setrepairType("");
    setShowRepairFilter(false);
  };

  const closeComercio = () => {
    setCommercialType("");
    setCloseComercion(false);
  };

  const closeMinarea = () => {
    setMinArea("");
  };
  const closeMaxarea = () => {
    setMaxArea("");
  };
  const closeRoom = () => {
    setRooms(0);
  };
  const closeToilets = () => {
    setToilets(0);
  };
  const closeBuilt = () => {
    setNewBuilt("");
  };
  const closeAnimals = () => {
    setAnimals("");
  };
  const closeBalcony = () => {
    setBalcony("");
  };
  const closeMinPricea = () => {
    setMinPrice("");
  };
  const closeMaxPricea = () => {
    setMaxPrice("");
  };
  const clearAll = () => {
    setPaymentMethod("");
    setShowClose(false);
    setCity("");
    setShowCloseCity(false);
    setInYerevan("");
    setShowinYerevan(false);
    setbuildingType("");
    setShowTypeFilter(false);
    setrepairType("");
    setShowRepairFilter(false);
    setProperty("");
    setShowPropertyFilter(false);
    setCommercialType("");
    setCloseComercion(false);
    setMinArea("");
    setMaxArea("");
    setRooms(0);
    setToilets(0);
    setNewBuilt("");
    setAnimals("");
    setBalcony("");
    setMinPrice("");
    setMaxPrice("");
  };
  useEffect(() => {
    if (inYerevan === "") {
      setInYerevanFilter(false);
    }
  }, []);
  const [filterFields] = useState(["sale", "comer"]);
  const [filterFields2] = useState(["office", "premise", "business", "other"]);
  const [filterFields3] = useState([
    "yerevan",
    "gyumri",
    "tsaghkadzor",
    "dilijan",
    "Ijevan",
    "ejmiatsin",
    "vanadzor",
    "sevan",
    "dzoraghbyur",
  ]);
  const [filterFields4] = useState([
    "ajapnyak",
    "arabkir",
    "avan",
    "davtashen",
    "erebuni",
    "zeytun",
    "kentron",
    "malatia",
    "marash",
    "nornork",
    "nubarashen",
    "shengavit",
  ]);
  const [filterFields5] = useState(["stone", "panel", "monolith"]);
  const [filterFields6] = useState([
    "none",
    "old",
    "partial",
    "cosmetic",
    "euro",
    "design",
    "capital",
  ]);
  const [filterFields7] = useState(["A private house", "Flat"]);

  return (
    <>
      <div className="header__container header__wrapper">
        <div className="products__icons">
          <div>
            <p className="products__icons-name">{t("estate")}</p>
          </div>

          <div className="products__icons-img">
            <div className="filter__burger" onClick={() => setIsOpen(!isOpen)}>
              <img src={filterIcon} alt="" />
              <p> {t("filters")} </p>
            </div>
            <div className="products-icon">
              <img
                onClick={() => setItemCount(false)}
                src={treeIcon}
                alt="treeIcon"
                style={{ display: itemCount ? "block" : "none" }}
              />
              <img
                src={newcolor2}
                alt=""
                style={{ display: itemCount ? "none" : "block" }}
              />
              <img
                onClick={() => setItemCount(true)}
                src={fourIcon}
                alt="fourIcon"
                style={{ display: itemCount ? "none" : "block" }}
              />
              <img
                src={newcolor}
                alt=""
                style={{ display: itemCount ? "block" : "none" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={itemCount ? "crds__area" : "cards__areanew"}>
        <div className={isOpen ? "filter__card" : "filter__cardNew"}>
          <div className="container__filter">
            {/* filter1 start */}

            <div className="filter__block">
              <div className="question icons__question">
                <div
                  className="question__global"
                  onClick={(e) => setItemFilter(!itemFilter)}
                >
                  {paymentMethod}
                  <p className={paymentMethod ? "see" : "seeyou"}>
                    {t("estate")}
                  </p>

                  <div
                    className={
                      showClose ? "question__img" : "question__imgOpen"
                    }
                  >
                    <img
                      src={chervon}
                      alt="chervonOpen"
                      className={
                        itemFilter ? "question__img" : "question__imgOpen"
                      }
                    />
                  </div>

                  <img
                    src={chervonClose}
                    alt="chervonClose"
                    className={
                      itemFilter ? "question__imgOpen" : "question__imgClose"
                    }
                  />
                  <img
                    className={showClose ? "close__quesition" : "closer"}
                    src={closeIcon}
                    alt=""
                    onClick={(e) => closePaymentMethod()}
                  />
                </div>
              </div>

              {itemFilter && (
                <div className="answers">
                  {filterFields.map((option) => (
                    <div
                      onClick={(e) => {
                        if (option === "comer") {
                          setShowCommerciaFilter(true);
                        } else {
                          setShowCommerciaFilter(false);
                        }
                        if (option === "sale") {
                          setShowAnimalsFilter(true);
                        } else {
                          setShowAnimalsFilter(false);
                        }
                        setPaymentMethod(t(option));
                        setItemFilter(false);
                        setShowClose(true);
                      }}
                      className="answer-txt"
                    >
                      {t(option)}
                    </div>
                  ))}

                  <div className="answer__info">
                    <div
                      className="rents"
                      onClick={(e) => setRentFilter(!rentFilter)}
                    >
                      <p className="answer-txt"> {t("rent")}</p>
                      <div
                        className={
                          showClose ? "question__img" : "question__imgOpen"
                        }
                      >
                        <img
                          src={chervon}
                          alt="chervonOpen"
                          className={
                            rentFilter ? "question__img" : "question__imgOpen"
                          }
                        />
                      </div>
                      <img
                        src={chervonClose}
                        alt="chervonClose"
                        className={
                          rentFilter
                            ? "question__imgOpen"
                            : "question__imgClose"
                        }
                      />
                      <img
                        className={showClose ? "close__quesition" : "closer"}
                        src={closeIcon}
                        alt=""
                        onClick={(e) => closePaymentMethod()}
                      />
                    </div>
                    <div
                      className={
                        rentFilter
                          ? "rent__block answer-txt"
                          : "rent__blockClose"
                      }
                    >
                      <div>
                        {" "}
                        <input
                          type="radio"
                          id="monthly"
                          value={t("mount")}
                          onChange={RentChange}
                          checked={paymentMethod === t("mount")}
                          onClick={(e) => {
                            setItemFilter(false);
                            setRentFilter(false);
                            setShowClose(true);
                          }}
                        />
                        <label htmlFor="monthly">{t("mount")}</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="daily"
                          value={t("dail")}
                          onChange={RentChange}
                          checked={paymentMethod === t("dail")}
                          onClick={(e) => {
                            setItemFilter(false);
                            setRentFilter(false);
                            setShowClose(true);
                          }}
                        />
                        <label htmlFor="daily">{t("dail")}</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* property start */}
            {/* <div className='question__global' onClick={(e) => setCommerciaFilter(!commerciaFilter)}>
          
          {commercialType}
          <p className={commercialType?'see':"seeyou"}>Коммерческая</p>

            
            <div className=  { closeComercion ? "question__img":"question__imgOpen"}>
                <img src={chervon} alt="chervonOpen"   className={commerciaFilter?"question__img":"question__imgOpen"}/>
                </div>   
               
                 
                 <img src={chervonClose} alt="chervonClose"  className={commerciaFilter?"question__imgOpen":"question__imgClose"}
                 />
                <img className={closeComercion?'close__quesition':"closer"} src={closeIcon} alt="" 
                onClick={(e) =>closeComercio() }  />
         </div> */}

            <div
              className="filter__block"
              style={{ display: showcommerciaFilter ? "none" : "block" }}
            >
              <div className="question">
                <div
                  className="question__global"
                  onClick={(e) => setPropertyFilter(!propertyFilter)}
                >
                  {property}
                  <p className={property ? "see" : "seeyou"}>{t("Property")}</p>
                  <div
                    className={
                      showPropertyFilter ? "question__img" : "question__imgOpen"
                    }
                  >
                    <img
                      src={chervon}
                      alt="chervonOpen"
                      className={
                        propertyFilter ? "question__img" : "question__imgOpen"
                      }
                    />
                  </div>

                  <img
                    src={chervonClose}
                    alt="chervonClose"
                    className={
                      propertyFilter
                        ? "question__imgOpen"
                        : "question__imgClose"
                    }
                  />
                  <img
                    className={
                      showPropertyFilter ? "close__quesition" : "closer"
                    }
                    src={closeIcon}
                    alt=""
                    onClick={(e) => closeRepair()}
                  />
                </div>
              </div>

              {propertyFilter && (
                <div className="answers">
                  {filterFields7.map((option) => (
                    <div
                      onClick={(e) => {
                        setProperty(t(option));
                        setPropertyFilter(false);
                        setShowPropertyFilter(true);
                      }}
                      className="answer-txt"
                    >
                      {t(option)}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* property end */}

            {/* filter1 end */}
            <div
              className="filter__block noOpen"
              style={{ display: showcommerciaFilter ? "block" : "none" }}
            >
              <div className="question">
                <div
                  className="question__global"
                  onClick={(e) => setCommerciaFilter(!commerciaFilter)}
                >
                  {commercialType}
                  <p className={commercialType ? "see" : "seeyou"}>
                    {t("comer")}
                  </p>

                  <div
                    className={
                      closeComercion ? "question__img" : "question__imgOpen"
                    }
                  >
                    <img
                      src={chervon}
                      alt="chervonOpen"
                      className={
                        commerciaFilter ? "question__img" : "question__imgOpen"
                      }
                    />
                  </div>

                  <img
                    src={chervonClose}
                    alt="chervonClose"
                    className={
                      commerciaFilter
                        ? "question__imgOpen"
                        : "question__imgClose"
                    }
                  />
                  <img
                    className={closeComercion ? "close__quesition" : "closer"}
                    src={closeIcon}
                    alt=""
                    onClick={(e) => closeComercio()}
                  />
                </div>
              </div>
              {commerciaFilter && (
                <div className="answers">
                  {filterFields2.map((option) => (
                    <div
                      onClick={(e) => {
                        setCommercialType(t(option));
                        setCommerciaFilter(false);
                        setCloseComercion(true);
                        if (option === "yerevan") {
                          setShowRegionFilter(true);
                        } else {
                          setShowRegionFilter(false);
                        }
                      }}
                      className="answer-txt"
                    >
                      {t(option)}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa */}

            {/* filter2 start */}

            <div className="filter__block">
              <div className="question">
                <div
                  className="question__global"
                  onClick={(e) => setRegionFilter(!regionFilter)}
                >
                  {city}
                  <p className={city ? "see" : "seeyou"}>{t("region")}</p>

                  <div
                    className={
                      showCloseCity ? "question__img" : "question__imgOpen"
                    }
                  >
                    <img
                      src={chervon}
                      alt="chervonOpen"
                      className={
                        regionFilter ? "question__img" : "question__imgOpen"
                      }
                    />
                  </div>

                  <img
                    src={chervonClose}
                    alt="chervonClose"
                    className={
                      regionFilter ? "question__imgOpen" : "question__imgClose"
                    }
                  />
                  <img
                    className={showCloseCity ? "close__quesition" : "closer"}
                    src={closeIcon}
                    alt=""
                    onClick={(e) => closeCity()}
                  />
                </div>
              </div>

              {regionFilter && (
                <div className="answers">
                  {filterFields3.map((option) => (
                    <div
                      onClick={(e) => {
                        setCity(t(option));
                        setRegionFilter(false);
                        setShowCloseCity(true);
                        if (option === "yerevan") {
                          setShowRegionFilter(true);
                        } else {
                          setShowRegionFilter(false);
                        }
                      }}
                      className="answer-txt"
                    >
                      {t(option)}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* filter2 end */}

            {/* filter InYerevan */}

            <div
              className="filter__block noOpen"
              style={{ display: showRegionFilter ? "block" : "none" }}
            >
              <div className="question">
                <div
                  className="question__global"
                  onClick={(e) => setInYerevanFilter(!inYerevanFilter)}
                >
                  {inYerevan}
                  <p className={inYerevan ? "see" : "seeyou"}>
                    {t("districts")}
                  </p>

                  <div
                    className={
                      showinYerevan ? "question__img" : "question__imgOpen"
                    }
                  >
                    <img
                      src={chervon}
                      alt="chervonOpen"
                      className={
                        inYerevanFilter ? "question__img" : "question__imgOpen"
                      }
                    />
                  </div>

                  <img
                    src={chervonClose}
                    alt="chervonClose"
                    className={
                      inYerevanFilter
                        ? "question__imgOpen"
                        : "question__imgClose"
                    }
                  />
                  <img
                    className={showinYerevan ? "close__quesition" : "closer"}
                    src={closeIcon}
                    alt=""
                    onClick={(e) => closeYerevan()}
                  />
                </div>
              </div>

              {inYerevanFilter && (
                <div className="answers">
                  {filterFields4.map((option) => (
                    <div
                      onClick={(e) => {
                        setInYerevan(t(option));
                        setInYerevanFilter(true);
                        setShowinYerevan(true);
                        if (inYerevan !== "") {
                          setInYerevanFilter(false);
                        } else {
                          setInYerevanFilter(true);
                        }
                      }}
                      className="answer-txt"
                    >
                      {t(option)}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* filter3 start */}

            <div
              className="filter__block"
              style={{ display: !showcommerciaFilter ? "block" : "none" }}
            >
              <div className="question">
                <div
                  className="question__global"
                  onClick={(e) => setTypeFilter(!typeFilter)}
                >
                  {buildingType}
                  <p className={buildingType ? "see" : "seeyou"}>
                    {t("building")}
                  </p>
                  <div
                    className={
                      showTypeFilter ? "question__img" : "question__imgOpen"
                    }
                  >
                    <img
                      src={chervon}
                      alt="chervonOpen"
                      className={
                        typeFilter ? "question__img" : "question__imgOpen"
                      }
                    />
                  </div>

                  <img
                    src={chervonClose}
                    alt="chervonClose"
                    className={
                      typeFilter ? "question__imgOpen" : "question__imgClose"
                    }
                  />
                  <img
                    className={showTypeFilter ? "close__quesition" : "closer"}
                    src={closeIcon}
                    alt=""
                    onClick={(e) => closesType()}
                  />
                </div>
              </div>

              {typeFilter && (
                <div className="answers">
                  {filterFields5.map((option) => (
                    <div
                      onClick={(e) => {
                        setbuildingType(t(option));
                        setTypeFilter(false);
                        setShowTypeFilter(true);
                      }}
                      className="answer-txt"
                    >
                      {t(option)}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* filter3 end */}

            {/* filter4 start */}

            <div className="filter__block">
              <p className="total-txt">{t("area")}</p>

              <div className="total__area">
                <input
                  type="text"
                  className="total__text"
                  placeholder={t("min")}
                  onChange={minarea}
                  value={minArea}
                />
                {/* <img src={closeIcon} alt="close" className={minArea?'close__quesition':"closer"}  type="submit" value="Send"  onClick={closeMinarea}/> */}

                <input
                  type="text"
                  className="total__text"
                  placeholder={t("max")}
                  onChange={maxarea}
                  value={maxArea}
                />
                {/* <img src={closeIcon} alt="close" className={maxArea?'close__quesition':"closer"}  type="submit" value="Send"  onClick={closeMaxarea}/> */}
              </div>
            </div>

            {/* filter4 end */}

            {/* filter5 start */}

            <div className="filter__block">
              <div className="question">
                <div
                  className="question__global"
                  onClick={(e) => setRepairFilter(!repairFilter)}
                >
                  {repairType}
                  <p className={repairType ? "see" : "seeyou"}>{t("repair")}</p>
                  <div
                    className={
                      showRepairFilter ? "question__img" : "question__imgOpen"
                    }
                  >
                    <img
                      src={chervon}
                      alt="chervonOpen"
                      className={
                        repairFilter ? "question__img" : "question__imgOpen"
                      }
                    />
                  </div>

                  <img
                    src={chervonClose}
                    alt="chervonClose"
                    className={
                      repairFilter ? "question__imgOpen" : "question__imgClose"
                    }
                  />
                  <img
                    className={showRepairFilter ? "close__quesition" : "closer"}
                    src={closeIcon}
                    alt=""
                    onClick={(e) => closeRepair()}
                  />
                </div>
              </div>

              {repairFilter && (
                <div className="answers">
                  {filterFields6.map((option) => (
                    <div
                      onClick={(e) => {
                        setrepairType(t(option));
                        setRepairFilter(false);
                        setShowRepairFilter(true);
                      }}
                      className="answer-txt"
                    >
                      {t(option)}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* filter5 end */}

            {/* range area start */}

            <div
              className="range__global"
              style={{ display: !showcommerciaFilter ? "block" : "none" }}
            >
              <div className="rooms">
                <p>{t("room")}</p>
                <div>
                  <input
                    type="range"
                    min="0"
                    max={MAX}
                    onChange={(e) => setRooms(e.target.value)}
                    style={getBackgroundSize()}
                    value={rooms}
                  />
                  {rooms}
                  {/* <img src={closeIcon} alt="close" className={rooms?'close__quesition':"closer"}  type="submit" value="Send"  onClick={closeRoom}/> */}
                </div>
              </div>
              <div className="toilets">
                <p>{t("bathrooms")}</p>

                <div>
                  <input
                    type="range"
                    min="0"
                    max={MAX2}
                    onChange={(e) => setToilets(e.target.value)}
                    style={getBackgroundSize2()}
                    value={toilets}
                  />
                  {toilets}
                  {/* <img src={closeIcon} alt="close" className={toilets?'close__quesition':"closer"}  type="submit" value="Send"  onClick={closeToilets}/> */}
                </div>
              </div>
            </div>

            {/* range area end */}

            {/* filter8 start */}

            <div className="filter__block">
              <div className="building">
                <p className="building__text">{t("newbuilding")}</p>
                <div className="chekbox__input">
                  <div className="chekbox__input-item">
                    <input
                      type="radio"
                      id="building-yes"
                      value={t("yesBilde")}
                      checked={newBuilt === t("yesBilde")}
                      onChange={newbuilt}
                    />
                    <label htmlFor="building-yes">{t("yesBilde")}</label>
                    <img
                      src={closeIcon}
                      alt="close"
                      className={
                        newBuilt === t("yesBilde")
                          ? "close__quesition"
                          : "closer"
                      }
                      type="submit"
                      value="Send"
                      onClick={closeBuilt}
                    />
                  </div>
                  <div className="chekbox__input-item">
                    <input
                      type="radio"
                      id="building-no"
                      value={t("noBilde")}
                      onChange={newbuilt}
                      checked={newBuilt === t("noBilde")}
                    />
                    <label htmlFor="building-no">{t("noBilde")}</label>
                    <img
                      src={closeIcon}
                      alt="close"
                      className={
                        newBuilt === t("noBilde")
                          ? "close__quesition"
                          : "closer"
                      }
                      type="submit"
                      value="Send"
                      onClick={closeBuilt}
                    />
                  </div>
                </div>
                <div className="underline"></div>
                <div style={{ display: !showAnimalsFilter ? "block" : "none" }}>
                  <div
                    style={{ display: !showcommerciaFilter ? "block" : "none" }}
                  >
                    <div
                      className={
                        animalsActive ? "chekbox__input yeschek" : "nochek"
                      }
                    >
                      <div className="chekbox__input-item">
                        <input
                          type="radio"
                          id="building-yesanimals"
                          value="WithAnimals"
                          checked={animals === "WithAnimals"}
                          onChange={animalsChange}
                        />
                        <label htmlFor="building-yesanimals">
                          {t("withanimals")}{" "}
                        </label>
                        <img
                          src={closeIcon}
                          alt="close"
                          className={
                            animals === "WithAnimals"
                              ? "close__quesition"
                              : "closer"
                          }
                          type="submit"
                          value="Send"
                          onClick={closeAnimals}
                        />
                      </div>
                      <div className="chekbox__input-item">
                        <input
                          type="radio"
                          id="building-noanimals"
                          value="WithoutAnimals"
                          onChange={animalsChange}
                          checked={animals === "WithoutAnimals"}
                        />
                        <label htmlFor="building-noanimals">
                          {t("withoutanimals")}
                        </label>
                        <img
                          src={closeIcon}
                          alt="close"
                          className={
                            animals === "WithoutAnimals"
                              ? "close__quesition"
                              : "closer"
                          }
                          type="submit"
                          value="Send"
                          onClick={closeAnimals}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: !showAnimalsFilter ? "block" : "none" }}>
                  <div
                    style={{ display: !showcommerciaFilter ? "block" : "none" }}
                    className="underline"
                  ></div>
                </div>

                <div
                  className="chekbox__input"
                  style={{ display: !showcommerciaFilter ? "block" : "none" }}
                >
                  <div className="chekbox__input-item">
                    <input
                      type="radio"
                      id="building-yesbalcony"
                      value="WithBalcony"
                      checked={balcony === "WithBalcony"}
                      onChange={balconyChange}
                    />
                    <label htmlFor="building-yesbalcony">
                      {t("withbalcony")}{" "}
                    </label>
                    <img
                      src={closeIcon}
                      alt="close"
                      className={
                        balcony === "WithBalcony"
                          ? "close__quesition"
                          : "closer"
                      }
                      type="submit"
                      value="Send"
                      onClick={closeBalcony}
                    />
                  </div>
                  <div className="chekbox__input-item">
                    <input
                      type="radio"
                      id="building-nobalcony"
                      value="WithoutBalcony"
                      onChange={balconyChange}
                      checked={balcony === "WithoutBalcony"}
                    />
                    <label htmlFor="building-nobalcony">
                      {t("withoutbalcony")}
                    </label>
                    <img
                      src={closeIcon}
                      alt="close"
                      className={
                        balcony === "WithoutBalcony"
                          ? "close__quesition"
                          : "closer"
                      }
                      type="submit"
                      value="Send"
                      onClick={closeBalcony}
                    />
                  </div>
                </div>
                <div
                  className="underline"
                  style={{ display: !showcommerciaFilter ? "block" : "none" }}
                ></div>
              </div>
            </div>
            {/* filter8 end */}

            {/* filter9 start */}
            <div className="filter__price">
              <p className="filter__price-text">{t("price")}</p>

              <div className="total__area">
                <input
                  type="text"
                  className="total__text"
                  placeholder={t("min")}
                  onChange={minprice}
                  value={minPrice}
                />
                {/* <img src={closeIcon} alt="close" className={minPrice?'close__quesition':"closer"}  type="submit" value="Send"  onClick={closeMinPricea}/> */}

                <input
                  type="text"
                  className="total__text"
                  placeholder={t("max")}
                  onChange={maxprice}
                  value={maxPrice}
                />

                {/* <img src={closeIcon} alt="close" className={maxPrice?'close__quesition':"closer"}  type="submit" value="Send"  onClick={closeMaxPricea}/> */}
              </div>
            </div>

            {/* filter9 end   */}
            <div onClick={clearAll}>
              <div className="filter__end" >
                <button className="filter__btn" onClick={filterProduct}>
                  {" "}
                  {t("apply")}
                </button>
              </div>
            </div>
          </div>
        </div>

        {posts.length === 0 ? (
          <div>
            <p className="notData"> {t("data")}</p> 
           </div>
        ) : (
          <Cards
            itemCount={itemCount}
            posts={posts}
            setPosts={setPosts}
            loading={loading}
            totalCards={totalCards}
            currentButton={currentButton}
            setCurrentButton={setCurrentButton}
          />
        )}
      </div>
      <HomeOpen />
    </>
  );
}

export default Filters;
